<template>
  <transition name="fade">
    <div class="toast" v-show="showToast" :class="{ 'show': showToast }">
      {{ toastMessage }}
    </div>
  </transition>
  <div class="screen">
    <Loading v-model:active="isMakeBoxProcessing"
             loader="spinner"
             color="#1E2352"
             :opacity="1"
             :z-index="99">
    </Loading>
    <CheckoutPageHeaderBar title="ORDER" @open-exit-popup="openExitPopup"/>
    <CheckoutExitPopup
        :isActive="isExitPopupActive"
        @closeExitPopup="closeExitPopup"/>
    <div class="wrapper">

      <div class="order-info">
        <div class="product-info">
          <div class="info-title title3">
            Order Info
          </div>
          <div class="info-details">
            <div class="detail-thumbnail">
              <img :src="boxData.thumbnailUrl" alt="tarot-box-thumbnail" id="product-thumbnail">
            </div>
            <div class="detail-title title4">
              {{ boxData.title }}
            </div>
          </div>
        </div>
        <div class="price-info title4">
          Price
          <div class="prices">
            <div class="original-price">
              ${{ originalPriceString }}
            </div>
            <div class="new-price title2">
              ${{ salePriceString }}
            </div>
          </div>
        </div>
      </div>
      <hr style="height: 8px; width: 100%; background: #F5F5F5; border: none; margin: 0">
      <div class="user-info">
        <div class="user-info-header title3">
          Enter Email to Get Your Reading
        </div>
        <div class="user-nickname">
          <div class="input-label">
            Name/Nickname
          </div>
          <input v-model="userName" @input="userNameInputHandler" class="text-input title3" :class="{ 'error-input': !isValidName }" placeholder="Enter your name/nickname"/>
        </div>
        <div class="user-email">
          <div class="input-label">
            E-mail
          </div>
          <input v-model="userEmail" type="email" class="text-input title3" :class="{ 'error-input': !isValidEmail }" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="Enter your email address"/>
        </div>
        <div class="disclaimer-section">
          <div class="disclaimer">
            Refunds are <span class="highlight">not possible</span> due to incorrect email entry.<br/>
            <span class="highlight">Please fill it out carefully</span>
          </div>
          <div class="disclaimer-contact">
            Customer Service: dev@1zlabs.com
          </div>
        </div>
      </div>
      <CheckoutConsentForm
          @update:agreements="updateAgreements"
          @update:isAllAgree="updateIsAllAgree"
      />
    </div>
    <div style="height: 28px"></div>
    <button class="button buttonL" :disabled="!canGoNext || isStripeProcessing" :class="{ 'enabled': canGoNext || !isStripeProcessing }" @click="handleBuyNowButtonClick">
      <img :src="require('@/tarot_box/pages/web_checkout/assets/stripe.svg')" style="margin-right: 10px;"> BUY NOW
    </button>
  </div>
  <StripeCheckoutPopup
      v-if="showCheckoutForm"
      :user-box-id="boxId"
      :user-email="userEmail"
      :user-input-data="userInputData()"
      @close="showCheckoutForm = false"
      @payment-success="handlePaymentSuccess"
      @payment-fail="handlePaymentFail"
      @toast="showToastMessage"
      @update:transactionId="transactionId = $event"
  />
</template>

<script>
import CheckoutConsentForm from "@/tarot_box/pages/web_checkout/components/CheckoutConsentForm.vue";
import CheckoutVerifyPopup from "@/tarot_box/pages/web_checkout/components/CheckoutVerifyPopup.vue";
import CheckoutPageHeaderBar from "@/tarot_box/pages/web_checkout/components/CheckoutPageHeaderBar.vue";
import CheckoutExitPopup from "@/tarot_box/pages/web_checkout/components/CheckoutExitPopup.vue";

import {mapActions, mapGetters} from "vuex";
import {getMastroBoxList, proceedTestCheckout, makeBoxResultForMastro } from "@/tarot_box/helper/mytarot_api";
import Loading from "vue-loading-overlay";
import StripeCheckoutPopup from "@/tarot_box/pages/web_checkout/components/StripeCheckoutPopup.vue";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "WebCheckoutPage",
  components: {
    StripeCheckoutPopup,
    Loading,
    CheckoutPageHeaderBar, CheckoutConsentForm, CheckoutVerifyPopup, CheckoutExitPopup
  },
  props: {
    boxId: Number || String,
  },
  data() {
    return {
      boxData: {
        title: '',
        originalPrice: null,
        salePrice: null,
        thumbnailUrl: null,
        id: 0,
      },
      userPhoneNumber: null,
      userEmail: null,
      userName: null,
      userId: null,
      userPickedCards: '',
      userBoxHistoryId: null,
      userBoxCreatedAt: null,

      validName: false,
      validEmail: false,

      installReferrer: '',
      agreements: {
        service: false,
        privacy: false,
      },
      isAllAgree: false,
      isVerified: false,
      isMakeBoxProcessing: false,
      isStripeProcessing: false,
      isPaypleProcessing: false,
      isExitPopupActive: false,
      verificationCode: null,
      authKey: null,
      returnUrl: null,
      showToast: false,
      showCheckoutForm: false,
      toastMessage: '',
      transactionId: '',
      isRetry: false,
      isDevMode: false,
    }
  },
  mounted() {
    if (!this.inputData) {
      this.$router.push(`/tarot_box/input_v2/${this.boxId}`);
      return;
    }
    if(this.user.userNickname){
      this.userName = this.user.userNickname;
    }
    window.onBackButtonClick = this.handleBackButtonClick;
    this.adjustViewportHeight();
    window.addEventListener('resize', this.adjustViewportHeight);
    getMastroBoxList().then(res => {
      if (res.result === 'success') {
        const boxData = res.data.find(box => box.id === parseInt(this.boxId));
        if (boxData) {
          this.boxData.title = boxData.title;
          this.boxData.originalPrice = boxData.original_price;
          this.boxData.salePrice = boxData.sale_price;
          this.boxData.thumbnailUrl = boxData.thumbnail_url;
        }
      }
    });
    if (this.user?.webReferrer) {
      this.installReferrer = this.user.webReferrer;
    }
    logEvent('order_into', {'web_referrer': this.utmReferrer});
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustViewportHeight);
  },
  watch: {
    userEmail(newValue) {
      if (newValue && newValue.endsWith('@1zlabs.com')) {
        this.isDevMode = true;
        this.showToastMessage('Dev Mode Enabled');
      } else {
        this.isDevMode = false;
      }
    }
  },
  computed: {
    ...mapGetters(['user','tarotBoxes','tempCardData','tempInputData','inputData','utmReferrer']),
    formattedPhoneNumber: {
      get() {
        if (this.userPhoneNumber === null || this.userPhoneNumber === undefined) {
          return '';
        }
        return this.userPhoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1 $2 $3');
      },
      set(newValue) {
        this.userPhoneNumber = newValue.replace(/\D/g, '');
      }
    },
    isButtonEnabled() {
      return this.isAllAgree && this.userName !== null && this.userName !=="" && this.userPhoneNumber?.length === 11;
    },
    isValidName() {
      return !this.userName || this.userName.length <= 20;
    },
    isValidEmail() {
      return !this.userEmail || this.userEmail.match(/[^@\s]+@[^@\s]+\.[^@\s]+/);
    },
    canGoNext() {
      return this.isAllAgree && this.userName && this.isValidName && this.userEmail && this.isValidEmail;
    },
    originalPriceString() {
      if (this.boxData.originalPrice) {
        return parseFloat(this.boxData.originalPrice).toFixed(2);
      }
      return '';
    },
    salePriceString() {
      if (this.boxData.salePrice) {
        return parseFloat(this.boxData.salePrice).toFixed(2);
      }
      return '';
    },
  },
  methods: {
    ...mapActions(['setUserData']),
    adjustViewportHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    userInputData() {
      return this.inputData;
    },
    userNameInputHandler(event) {
      const inputValue = event.target.value;
      const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣\s.]/g, '');

      if (inputValue !== sanitizedValue) {
        event.target.value = sanitizedValue;
      }
    },
    showToastMessage(message){
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
      }, 4000);
    },
    handleBackButtonClick() {
      console.log(this.$route.meta.fromPath);
      if (!this.$route.meta.fromPath.includes('result')) {
        this.$router.go(-2);
        return 'back';
      } else {
        this.$router.go(-2);
        return 'back';
      }
    },
    updateAgreements(value) {
      this.agreements = value;
    },
    updateIsAllAgree(value) {
      this.isAllAgree = value;
    },
    async finishTestCheckout(){
      this.isStripeProcessing = true;
      const boxRes = await makeBoxResultForMastro(this.boxId, this.userEmail, this.inputData);
      if(boxRes.result==='success') {
        this.userBoxHistoryId = boxRes.data.id;
        console.log('historyId generated: ', this.userBoxHistoryId);
        const transactionRes = await proceedTestCheckout(this.userBoxHistoryId);
        if(transactionRes.result==='success'){
          this.transactionId = transactionRes.data.transaction_id;
          this.showToastMessage('Test Box Successfully Generated');
          setTimeout(() => {
            console.log('end')
          }, 3000);
        } else {
          this.showToastMessage('Call Rob')
        }
      }
      this.isStripeProcessing = false;
    },


    async handleBuyNowButtonClick() {
      if(this.isDevMode){
        this.finishTestCheckout();
        return;
      }
      console.info('before payment', this.boxId, this.userEmail, this.inputData);
      logEvent('order_cta_click', {
        'web_referrer' : this.utmReferrer,
        'user_id': this.userId,
        'box_id': this.boxId,
        'email': this.userEmail
      });
      this.showCheckoutForm = true;
      logEvent('stripe_into', {
        'web_referrer' : this.utmReferrer,
        'user_id': this.userId,
        'box_id': this.boxId,
        'email': this.userEmail
      });
    },
    async handlePaymentFail() {
      this.showToastMessage('Payment Failed');
    },
    async handlePaymentSuccess() {
      this.userBoxHistoryId = null;

      const params = {
        box_price: this.boxData.salePrice,
        box_id: this.boxId,
        box_title: this.boxData.title,
        transaction_id: this.transactionId,
      };

      this.$router.push({
        path: '/tarot_box/purchase_complete',
        query: params
      });
    },
    openExitPopup(){
      this.isExitPopupActive = true;
    },
    closeExitPopup(){
      this.isExitPopupActive = false;
    },
    filterUserName(event) {
      // 한글, 영문, 숫자만 허용하는 정규표현식
      const regex = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]+$/;
      this.userName = event.target.value.split('').filter(char => regex.test(char)).join('');
    },

    preventInvalidInput(event) {
      // 한글, 영문, 숫자만 허용
      const regex = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣]$/;
      if (!regex.test(event.key) && !this.isAllowedKey(event)) {
        event.preventDefault();
      }
    },

    isAllowedKey(event) {
      // 특정 기능 키들을 허용 (백스페이스, 삭제, 화살표 등)
      const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
      return allowedKeys.includes(event.key);
    },
  },
}
</script>

<style scoped>
.screen{
  position: relative;
  width: 100vw;
  height: 100dvh;
  overflow-y: scroll;
  max-width: 480px;
  margin: 0 auto;
  box-sizing: border-box;
}

.order-info{
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 24px;
  box-sizing: border-box;

  text-align: left;
}

.wrapper {
  width: 100%;
}

.product-info{
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 26px;
  border-bottom: 0.5px solid var(--Grayscale-Gray7);
}

.price-info{
  display: flex;
  flex-direction: row;
  padding-top: 26px;
  justify-content: space-between;
  align-items: center;
}

.prices{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}


.info-details{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  gap: 12px;
}

#product-thumbnail {
  width: 46px;
  height: 60px;
  object-fit: cover;
}

.original-price{
  font-size: 16px;
  font-weight: 500;
  color: var(--Grayscale-Gray6);
  text-decoration: line-through;
}

.user-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: start;
  box-sizing: border-box;
  padding: 24px;
}

.user-info-header {
  margin-bottom: 26px;
}

.input-label {
  font-size: 14px;
  font-weight: 800;
}

.text-input {
  width: 100%;
  height: 48px;
  padding: 13px 16px;
  margin-top: 8px;
  font-size: 20px;
  font-weight: 700;
  border: 2px solid var(--Grayscale-Gray10);
  border-radius: 8px;
  box-sizing: border-box;
}

.text-input::placeholder {
  color: var(--Grayscale-Gray8);
}

.error-input {
  border-color: red;
}

.user-email {
  margin-top: 24px;
}

.disclaimer-section {
  margin-top: 20px;
  padding: 14px 12px;
  background-color: var(--Grayscale-Gray10);
  border-radius: 4px;
}

.disclaimer {
  font-size: 12px;
  font-weight: 400;
  color: var(--Grayscale-Gray3);
}

.highlight {
  color: #FF6666;
}

.disclaimer-contact {
  margin-top: 7px;
  font-size: 12px;
  font-weight: 700;
  color: var(--Grayscale-Gray7);
}

input{
  width: 100%;
  height: 40px;
  padding: 10px 0;
  margin: 4px 0;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #B2B2B2;
}

input::placeholder {
  color: #B2B2B2;
  font-size: 20px;
  font-weight: 700;
}

.button {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 480px;
  height: 52px;
  background: var(--Grayscale-Gray7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  cursor: not-allowed;
}

.button.enabled {
  background: var(--Grayscale-Gray3);
  cursor: pointer;
}

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
  width: 90vw;
  padding: 16px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-Gray2, #242424);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
  color: var(--Grayscale-Gray12, #FFF);
  text-align: center;
  opacity: 0;
  z-index: 10000;
  transition: opacity 0.5s, transform 0.5s;
}
.toast.show {
  opacity: 1;
  transform: translate(-50%, -50%);
  transition: opacity 0.5s, transform 0.5s;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
