<template>
  <div v-safe-area-top class="header-bar" :style="{ backgroundColor: bgColor }" @click="clickCountForDevMode++">
    <img id="logo" src="../../assets/images/home-logo.png" alt="logo">
    <img id="search" src="" alt="search">
    <div v-web class="app-download-button" style="transform: translateY(-2px)" @click="goAppStore">
      <svg width="74" height="26" viewBox="0 0 74 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="72" height="24" rx="12" fill="white"/>
        <rect x="1" y="1" width="72" height="24" rx="12" stroke="black" stroke-width="0.5"/>
        <path d="M12.3365 7.928C13.7405 7.928 14.7365 8.912 14.7365 10.328C14.7365 11.732 13.7405 12.716 12.3365 12.716C10.9325 12.716 9.93648 11.732 9.93648 10.328C9.93648 8.912 10.9325 7.928 12.3365 7.928ZM10.8845 10.328C10.8845 11.24 11.4965 11.864 12.3365 11.864C13.1765 11.864 13.8005 11.24 13.8005 10.328C13.8005 9.404 13.1765 8.78 12.3365 8.78C11.4965 8.78 10.8845 9.404 10.8845 10.328ZM11.7965 17.768V13.568H12.8285V14.78H17.7485V13.568H18.7565V17.768H11.7965ZM12.8285 16.916H17.7485V15.608H12.8285V16.916ZM15.5045 13.028V7.544H16.4765V9.74H17.7605V7.352H18.7565V13.1H17.7605V10.592H16.4765V13.028H15.5045ZM28.3208 7.34H29.3168V17.9H28.3208V7.34ZM20.4608 11.9C20.4608 9.62 21.3488 8.204 22.7048 8.204C23.9888 8.204 24.8408 9.428 24.9608 11.42H26.2208V7.568H27.2048V17.372H26.2208V12.272H24.9608C24.8768 14.348 24.0128 15.608 22.7048 15.608C21.3488 15.608 20.4608 14.192 20.4608 11.9ZM21.4088 11.9C21.4088 13.604 21.9128 14.636 22.7048 14.636C23.5208 14.636 24.0128 13.604 24.0128 11.9C24.0128 10.196 23.5208 9.176 22.7048 9.176C21.9128 9.176 21.4088 10.196 21.4088 11.9ZM36.097 10.892H38.497V7.328H39.529V17.924H38.497V11.744H36.097V10.892ZM30.793 14.852C32.365 14.084 33.517 12.044 33.517 10.088V8.24H34.549V10.088C34.549 12.008 35.677 13.94 37.237 14.672L36.625 15.512C35.437 14.9 34.513 13.676 34.045 12.212C33.577 13.76 32.629 15.056 31.441 15.692L30.793 14.852ZM44.083 15.704H48.331V13.484H45.199V8.072H46.231V9.92H51.487V8.072H52.519V13.484H49.363V15.704H53.647V16.568H44.083V15.704ZM46.231 12.644H51.487V10.76H46.231V12.644ZM62.2032 7.34H63.2472V17.9H62.2032V7.34ZM54.6792 15.128C57.4872 13.784 58.8672 11.804 59.0712 9.32H55.1712V8.468H60.1272C60.1272 11.552 58.7952 14.228 55.2312 15.956L54.6792 15.128Z" fill="#121212"/>
      </svg>
    </div>
  </div>
</template>



<script>
// import {hideBottomBar} from "@/tarot_box/helper/native_api";

export default {
  name: "MainHeaderBar",
  props: {
    bgColor:{
      type: String,
      default: '#FFFFFF'
    }
  },
  data() {
    return {
      clickCountForDevMode: 0,
    }
  },
  watch : {
    clickCountForDevMode(){
      if(this.clickCountForDevMode === 20){
        const tarot_user_id = this.$route.query.tarot_user_id;
        const phone_number = this.$route.query.phone_number;
        const os_type = new URLSearchParams(window.location.search).get('os_type');
        if(window.location.hostname === 'my-tarot-vue-stage.web.app') {
          // hideBottomBar();
          window.location.href = `https://my-tarot-vue-stage.web.app/tarot_box/home?tarot_user_id=${tarot_user_id}&phone_number=${phone_number}&os_type=${os_type}`;
        } else {
          window.location.href = `https://my-tarot-vue-stage.web.app/tarot_box/home?tarot_user_id=${tarot_user_id}&phone_number=${phone_number}&os_type=${os_type}`;
        }
      }
    }
  },
  methods: {
    goAppStore() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log(userAgent)
      if (/android/i.test(userAgent)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.onez.mytarotgpt&hl=ko&gl=US&pli=1&1z_external_browser=true';
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        window.location.href = 'https://apps.apple.com/kr/app/id6446103793?1z_external_browser=true';
      } else {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.onez.mytarotgpt&hl=ko&gl=US&pli=1&1z_external_browser=true';
      }
    }
  }
}
</script>


<style scoped>
.header-bar{
  height: 68px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 7px;
  border-bottom: 0.5px solid #E5E5E5;
}

#logo{
  margin-left: 19px;
  height: 44px;
  width: auto;
}

#search{
  display: none;
  height: 44px;
  width: auto;
  margin-right: 22px;
}

.app-download-button{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  right: 24px;
}
</style>
