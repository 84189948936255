<template>
  <input
      :value="modelValue"
      placeholder="숫자를 입력해주세요"
      :maxlength="maxLength"
      type="tel"
      class="text-input"
      @input="updateValue"
      @keydown="preventInvalidInput"
      @keyup.enter="submitAnswer"
      @blur="submitAnswer"
      ref="input">
</template>

<script>
export default {
  name: "NumberInput",
  props: {
    inputItem: {
      type: Object,
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 20
    }
  },
  methods: {
    updateValue(event) {
      const numericValue = event.target.value.replace(/[^0-9]/g, '');
      this.$emit('focus')
      this.$emit('update:modelValue', numericValue)
    },
    preventInvalidInput(event) {
      // 숫자만 허용
      const regex = /^[0-9]$/;
      if (!regex.test(event.key) && !this.isAllowedKey(event)) {
        event.preventDefault();
      }
    },
    isAllowedKey(event) {
      // 특정 기능 키들을 허용 (백스페이스, 삭제, 화살표 등)
      const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End', 'Tab'];
      return allowedKeys.includes(event.key) ||
          (event.ctrlKey && event.key === 'a') || // Ctrl+A
          (event.metaKey && event.key === 'a');   // Command+A
    },
    submitAnswer() {
      if (this.modelValue.trim()) {
        this.$emit('submitAnswer', {
          ...this.inputItem,
          value: this.modelValue,
          inputFormType: this.inputItem?.inputFormType,
          inputFormSubtype: this.inputItem?.inputFormSubtype
        })
        this.$refs.input.blur();
      }
    }
  },
  mounted() {
    this.$refs.input.focus()
  },
  emits: ['update:modelValue', 'submitAnswer', 'focus']
}
</script>

<style scoped>
.text-input{
  width: 100%;
  padding: 24px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  outline: 2px solid var(--Grayscale-Gray10);
  border-bottom: none;
}

.text-input:focus{
  outline: 2px solid var(--Color-palette-Primary-Default);
}

.text-input::placeholder{
  font-size: 18px;
  color: var(--Grayscale-Gray8, #8C8C8C);
}
</style>