<template>
  <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
    <div class="overlay" @click="backgroundClick"></div>
    <div class="popup-container" ref="popupContainer">
      <div class="x-mark" @click="backgroundClick">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black"/>
        </svg>
      </div>
      <div class="selections">
        <span class="title3">
          문자로 받은<br>
          인증번호를 입력해주세요
        </span>
        <input type="tel" v-model="verificationCode" maxlength="5" placeholder="인증번호 5자리"/>
      </div>
      <div class="popup_footer">
        <button class="button" :disabled="!isButtonEnabled" :class="{'enabled': isButtonEnabled}" @click="submitVerificationCode">인증하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import {finishSMSAuth, getPayplePartnerAuth} from "@/tarot_box/helper/mytarot_api";
import {logEvent} from "@/tarot_box/helper/native_api";
import {mapGetters} from "vuex";

export default {
  name: "CheckoutVerifyPopup",
  data() {
    return {
      verificationCode: null,
      isLoading: false,
      userId: null,
      AuthKey: null,
      returnUrl: null,
    }
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    displayName: {
      type: String,
      default: '',
    },
    installReferrer: {
      type: String,
    },
  },
  emits: ['closePopup', 'verificationSuccess', 'showToastMessage'],
  mounted() {
    window.onBackButtonClick = null;
    window.onBackButtonClick = this.handleButtonClick;
  },
  beforeUnmount() {
    window.onBackButtonClick = null;
  },
  computed: {
    ...mapGetters(['user']),
    isButtonEnabled() {
      return this.verificationCode !== null && this.isLoading === false && this.verificationCode.length === 5;
    }
  },
  methods: {
    handleButtonClick() {
      if(this.isActive === true) {
        this.backgroundClick();
        return 'back';
      } else{
        return 'end';
      }
    },
    backgroundClick() {
      this.$emit('closePopup');
    },
    async submitVerificationCode() {
      if (this.isButtonEnabled && !this.isLoading) {
        this.isLoading = true;
        let res = await finishSMSAuth(this.phoneNumber, this.verificationCode, this.displayName, this.installReferrer);
        if (res.result === 'success'){
          logEvent('purchase_auth_click', {});
          console.log('제출성공: ', this.phoneNumber, this.verificationCode, this.displayName, this.installReferrer)
          this.userId = res.data.id;
          let payRes = await getPayplePartnerAuth();
          if (payRes.result === 'success'){
            this.authKey = payRes.data.auth_key;
            this.returnUrl = payRes.data.return_url;
          }
          this.$emit('verificationSuccess', {
            userId: this.userId,
            authKey: this.authKey,
            returnUrl: this.returnUrl,
          });
          this.backgroundClick();
        }else{
          this.$emit('showToastMessage', '인증번호가 일치하지 않습니다');
          this.isLoading = false;
          this.verificationCode = "";
        }
      }
    },
  }
}
</script>

<style>
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 500;
}

.popup-container {
  position: fixed;
  bottom: 0; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, 0); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: 100vw;
  padding: 52px 0 30px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 501;
  border-radius: 8px 8px 0 0;
}

.selections{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin: 0 24px;
}

.x-mark{
  position: fixed;
  top: 18px;
  right: 18px;
}

input{
  width: 100%;
  height: 40px;
  padding: 10px 0;
  margin: 4px 0;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #B2B2B2;
}

input::placeholder {
  color: #B2B2B2;
  font-size: 20px;
  font-weight: 700;
}

.popup_footer{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;
  margin-bottom: 0;
}

.popup_footer button{
  width: 100%;
  height: 56px;
  background: var(--Grayscale-Gray7, #B2B2B2);
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #FFFFFF;
}

.popup_footer button.enabled{
  background: var(--Color-palette-Primary-Default);
}
</style>