<template>
  <div class="item-container">
    <div class="item-infos">
      <div class="item-thumbnail"
           @click="handleContentClick">
        <ShimmerImage
            :src="item.thumbnail_img_url ? item.thumbnail_img_url : 'https://firebasestorage.googleapis.com/v0/b/meta-play-project.appspot.com/o/thumbnail_default.jpg?alt=media&token=cbd4c705-25c2-467c-a105-c48e0687e3a2'"
            :imageStyle="{ width: '65px' }"
            :heightRatio="1.4"
            :isCircle="false"
            :borderRadius="'2px'"/>
      </div>
      <div class="item-texts">
        <MyTarotItemContent :item="item" @click="handleContentClick"/>
      </div>
    </div>
    <div class="extra-button" @click="openExtraPopup">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3" cy="10" r="2" fill="#CCCCCC"/>
        <circle cx="10" cy="10" r="2" fill="#CCCCCC"/>
        <circle cx="17" cy="10" r="2" fill="#CCCCCC"/>
      </svg>
    </div>
    <MyTarotItemFooter
        :item="item"
        @click-review="handleFooterClick"
        @click-extra="handleextraClick"/>
  </div>

  <ExtraPopup
      :is-active="extraPopupActive"
      :close-popup-callback="closeExtraPopup"
      :open-delete-confirm-popup-callback="openDeleteConfirmPopup"
            />
  <DeleteConfirmPopup
      :is-active="deleteConfirmPopupActive"
      @deleteConfirmed="handleDeleteConfirmed"
      :item="item"
      :close-confirm-popup-callback="closeDeleteConfirmPopup"/>

  <BoxPaymentPopup v-if="paymentRequestPopupActive"
                   :boxPrice="item.price"
                   :tarotBoxHistoryId="item.id"
                   :item="item"
                    :close-popup-callback="closePaymentRequestPopup"
                   @update-user-info="$emit('refresh-user-info')"
                   @paymentDone="openPaymentDonePopup"/>

  <PaymentDonePopup v-if="paymentDonePopupActive"
                    :close-popup-callback="closePaymentDonePopup"/>

</template>

<script>
import MyTarotItemContent from "@/tarot_box/pages/my_tarot/components/my_tarot_items/MyTarotItemContent";
import MyTarotItemFooter from "@/tarot_box/pages/my_tarot/components/my_tarot_items/MyTarotItemFooter";
import ExtraPopup from "@/tarot_box/pages/my_tarot/components/delete_popup/ExtraPopup";
import DeleteConfirmPopup from "@/tarot_box/pages/my_tarot/components/delete_popup/DeleteConfirmPopup";
import BoxPaymentPopup from "@/tarot_box/pages/my_tarot/components/payment_popup/BoxPaymentPopup";
import PaymentDonePopup from "@/tarot_box/pages/my_tarot/components/payment_popup/PaymentDonePopup";
import {changeBottomSafeAreaColor, hideBottomBar, logEvent} from "@/tarot_box/helper/native_api";
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";


export default {
  name: "MyTarotItem",
  components: {
    ShimmerImage,
    BoxPaymentPopup,
    PaymentDonePopup,
    DeleteConfirmPopup,
    ExtraPopup,
    MyTarotItemContent,
    MyTarotItemFooter
  },
  props: {
    item: {
      type: Object,
    },
    refreshBox: {
      type: Function,
    },
    buildNumber: {
      type: Number,
      default: 0
    },
    osType: {
      type: String,
      default: 'ios'
    }
  },
  data() {
    return {
      extraPopupActive : false,
      deleteConfirmPopupActive: false,
      paymentRequestPopupActive: false,
      paymentDonePopupActive: false,
    }
  },
  emits: ['deleteBox', 'refreshBox', 'refresh-user-info'],
  methods: {
    openExtraPopup() {
      this.extraPopupActive = true;
      logEvent('mytarot_list_option_click', {});
    },
    closeExtraPopup() {
      this.extraPopupActive = false;
    },
    openDeleteConfirmPopup(){
      this.extraPopupActive = false;
      this.deleteConfirmPopupActive = true;
      logEvent('mytarot_delete_popup', {});
    },
    closeDeleteConfirmPopup(){
      this.deleteConfirmPopupActive = false;
    },
    openPaymentRequestPopup(){
      this.paymentRequestPopupActive = true;
    },
    closePaymentRequestPopup(){
      this.paymentRequestPopupActive = false;
    },
    openPaymentDonePopup(){
      this.paymentRequestPopupActive = false;
      this.paymentDonePopupActive = true;
      this.$emit('refreshBox');
    },
    closePaymentDonePopup(){
      this.paymentDonePopupActive = false;
    },
    handleContentClick() {
      if (this.item.status === 'paid_and_waiting') {
        logEvent('mytarot_wait_popup', {});
        return;
      } else if (this.item.status === 'requested') {
        this.openPaymentRequestPopup();
      } else {
        hideBottomBar();
        try {
          const fullPath = this.item.result_page_url;
          const url = new URL(fullPath);
          const ogUrl = new URL(window.location.href);
          const hashId = url.searchParams.get("hash_id");
          const insetTop = ogUrl.searchParams.get("inset_top");
          const insetBottom = ogUrl.searchParams.get("inset_bottom");
          const tarotUserId = ogUrl.searchParams.get("tarot_user_id");
          const phoneNumber = ogUrl.searchParams.get("phone_number");
          if(this.item.view_format === 'v2') {
            const tags = this.item.tags;
            this.$router.push(`/tarot_box/result_v2/${this.item.tarot_box_id}?history_id=${this.item.id}&hash_id=${hashId}&build_number=${this.buildNumber}&tags=${tags}&os_type=${this.osType}&inset_top=${insetTop}&inset_bottom=${insetBottom}&tarot_user_id=${tarotUserId}&phone_number=${phoneNumber}`);
          }else {
            this.$router.push(`/tarot_box/result/${this.item.tarot_box_id}?history_id=${this.item.id}&hash_id=${hashId}&build_number=${this.buildNumber}&os_type=${this.osType}&inset_top=${insetTop}&inset_bottom=${insetBottom}`);
          }
        } catch (error) {
          // URL 파싱에서 예외가 발생했을 때 실행될 코드
          console.error("URL 파싱 중 오류 발생: ", error);
        }
      }
      logEvent('mytarot_list_click', {
        'tarot_box_id': this.item.tarot_box_id,
        'tarot_box_name': this.item.tarot_box_title,
        'tarot_box_history_id': this.item.id,
        'tarot_box_status': this.item.status
      });
    },
    handleFooterClick() {
      if (this.item.status === 'requested') {
        logEvent('mytarot_purchase_click', {
          'tarot_box_id': this.item.tarot_box_id,
          'tarot_box_name': this.item.tarot_box_title
        });
        this.openPaymentRequestPopup();
      } else if (this.item.status === 'paid_and_waiting') {
        return;
      } else {
        hideBottomBar();
        changeBottomSafeAreaColor('#1E2352');
        const fullPath = this.item.result_page_url; // 현재 경로와 쿼리 문자열을 가져옵니다.
        const subPath = fullPath.split('hash_id=')[1];
        logEvent('mytarot_review_click', {
          'tarot_box_id': this.item.tarot_box_id,
          'tarot_box_name': this.item.tarot_box_title
        });
        const insetTop = new URL(window.location.href).searchParams.get("inset_top");
        const insetBottom = new URL(window.location.href).searchParams.get("inset_bottom");
        this.$router.push(`/tarot_box/review/${this.item.tarot_box_id}?hash_id=${subPath}&history_id=${this.item.id}&build_number=${this.buildNumber}&os_type=${this.osType}&inset_top=${insetTop}&inset_bottom=${insetBottom}`);
      }
    },
    async handleextraClick() {
      try {
        const fullPath = this.item.result_page_url;
        const url = new URL(fullPath);
        const hashId = url.searchParams.get("hash_id");
        const insetTop = new URL(window.location.href).searchParams.get("inset_top");
        const insetBottom = new URL(window.location.href).searchParams.get("inset_bottom");

        if(this.item.view_format === 'v2') {
          const tags = 'extra';
          hideBottomBar();
          if(this.item.extra_question_status === 'active' || this.item.extra_question_status === 'processing'){
            logEvent('mytarot_extra_question_click', {
              'tarot_box_id': this.item.tarot_box_id,
              'tarot_box_name': this.item.tarot_box_title,
            })
          } else {
            logEvent('mytarot_extra_answer_click', {
              'tarot_box_id': this.item.tarot_box_id,
              'tarot_box_name': this.item.tarot_box_title,
            })
          }
          this.$router.push(`/tarot_box/result_v2/${this.item.tarot_box_id}?history_id=${this.item.id}&hash_id=${hashId}&build_number=${this.buildNumber}&tags=${tags}&os_type=${this.osType}&inset_top=${insetTop}&inset_bottom=${insetBottom}`);
        }else {
          hideBottomBar();
          this.$router.push(`/tarot_box/result/${this.item.tarot_box_id}?history_id=${this.item.id}&hash_id=${hashId}&build_number=${this.buildNumber}&os_type=${this.osType}&inset_top=${insetTop}&inset_bottom=${insetBottom}`);
        }
      } catch (error) {
        // URL 파싱에서 예외가 발생했을 때 실행될 코드
        console.error("URL 파싱 중 오류 발생: ", error);
      }
    },
    handleDeleteConfirmed(id) {
      this.$emit('deleteBox', id); // 부모 컴포넌트로 이벤트 전달
      logEvent('mytarot_delete_popup_delete', {
        'tarot_box_id': this.item.tarot_box_id,
        'tarot_box_name': this.item.tarot_box_title,
        'tarot_box_history_id': this.item.id,
        'tarot_box_status': this.item.status
      });
    }
  }
}
</script>

<style scoped>
.item-container{
  position: relative;
  display: flex;
  margin: 16px 16px;
  padding: 20px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 8px;
  border: 0.5px solid var(--Grayscale-Gray7, #ECECEC);
  background: var(--Grayscale-Gray12, #FFF);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.item-container:last-child{
  margin-bottom: 75px;
}

.extra-button{
  position: absolute;
  right: 16px;
  top: 20px;
}

.item-infos{
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.item-texts{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 7px;
  padding: 0 14px;
  box-sizing: border-box;
}

</style>