<template>
  <div class="screen">
    <div class="cta">
      <button @click="goInputPage">GET MY READING
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.666 16.21L6.98 15.524L11.474 11.016L6.98 6.522L7.666 5.822L12.86 11.016L7.666 16.21Z" fill="#333333"/>
        </svg>
      </button>
    </div>
    <div class="sections-container">
      <div class="section">
        <div class="content first" ref="1_intro">
            <img :src="require('@/tarot_box/pages/home/assets/icons/logo.png')" alt="logo" class="logo" />
          <div class="text-con">
            <img id="moon" :src="require('@/tarot_box/pages/home/assets/images/2_moon.png')"/>
            <img id="rose" :src="require('@/tarot_box/pages/home/assets/images/2_rose.png')"/>
            <div class="title-right">
              <img id="dot" :src="require('@/tarot_box/pages/home/assets/images/2_dot.png')"/>
              <h1>Zodiac</h1>
            </div>
            <div class="title-left">
              <h1>Love Guide</h1>
            </div>
          </div>
          <div class="sub-con">
            <div class="texts">
              <h1>Love feeling complicated?<br>
                Unlock it with the key<br>
                you’ve been missing</h1>
              <p>You need to know this for<br>your dream relationship.</p>
            </div>
          </div>
          <div class="content-table">
            <div class="table-con">
              <p>·Your Star Sign Vibe & Cosmic Forecast</p>
              <p>·How You Love: Relationship Vibes</p>
              <p>·Unlock Your Hidden Charms</p>
              <p>·Star-Crossed Crushes:<br>Who's Your Cosmic Match?</p>
              <p>·Love Hacks:<br>Boost Your Cosmic Match</p>
              <p>·The Universe Has a Message for You</p>
            </div>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2 8H14" stroke="white" stroke-width="3" stroke-linecap="round"/>
              <path d="M8 14L8 2" stroke="white" stroke-width="3" stroke-linecap="round"/>
            </svg>
            <div class="table-con white-texts">
              <h3>Personalized Answers</h3>
              <p>Personailzed answers to your questions.</p>
            </div>
          </div>
        </div>

        <div class="content three" ref="3_screen">
          <h2>Only Mastro</h2>
          <h1>Personalized Answers</h1>
          <p>Ask Anything you're curious<br>about regarding your soulmate!</p>
          <img src="@/tarot_box/pages/home/assets/images/phone-pink.png" alt="phone" class="phone-img"/>
        </div>

        <div class="content five" ref="3_video">
          <h1>Preview</h1>
          <div class="video-container">
            <video
                ref="previewVideo"
                class="video-js vjs-default-skin"
                preload="auto"
            ></video>
          </div>
        </div>

        <div class="content" ref="2_review">
          <div class="slider-con">
            <Flicking
                :options="{
                  renderOnlyVisible: false,
                  autoResize: true,
                  align: 'center',
                  circular: false,
                  panelsPerView: 1.1,
                  bound: false
                }"
                @moveStart="logSwipe"
                class="slider"
            >
              <div v-for="(item, idx) in textList" :key="idx" class="slide-text flicking-panel">
                <h4>{{ item.content }}</h4>
                <p>{{item.author}}</p>
              </div>
            </Flicking>
          </div>
        </div>


        <div class="notice" ref="4_precaution">
          <h1>Important Notice</h1>
          <ul>
          <li>Prices may change and items may be discontinued
            without prior notice.
          </li>
            <li>The interpretation of purchased content will be sent via email.</li>
            <li>lease ensure your email address is entered correctly.
              We are not responsible for any issues arising from
              an incorrectly entered email address.
            </li>
            <li>Refunds are not available for errors caused
              by incorrect email entry, and we cannot provide answers
              or resend the content in such cases.
            </li>
            <li>For inquiries and assistance: dev@1zlabs.com
            </li>
          </ul>
        </div>
        <div class="footer" ref="5_footer">
          <img :src="require('@/tarot_box/pages/home/assets/icons/logo.png')" alt="logo"/>
          <div>
            <span>SUPERKEN LLC EIN: 37-2146593</span>
            <span>800 N King Street Suite 304 1074 Wilmington, DE 19801</span>
            <span>HELP dev@1zlabs.com</span>
          </div>
          <div class="link">
            <a href="https://terms.1zlabs.com/mastro-terms-of-service/privacy-policy" target="_blank">PRIVACY</a>
            <a href="https://terms.1zlabs.com/mastro-terms-of-service" target="_blank">TERMS OF SERVICE</a>
          </div>
        </div>
    <div>
     </div>
      </div>
    </div>
    <div class="modal" v-if="show">
      <img :src="require('@/tarot_box/pages/home/assets/icons/x.png')" @click="show=false" class="exit"  v-if="show" alt="">
      <img :src="image" class="preview" v-if="show" alt=""/>
    </div>
  </div>
</template>

<script>
import Flicking from "@egjs/vue3-flicking";
import "@egjs/vue3-flicking/dist/flicking.css";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import {logEvent} from "@/tarot_box/helper/native_api";
import {mapActions} from "vuex";

export default {
  name: "HomePage",
  components:{
    Flicking
  },
  data() {
    return {
      utm: 'not set',
      textList: [
        { content: "I was so over dating apps, but now I finally know what kind of person I’m really looking for!", author: "Sophia"},
        { content: "I was feeling the pressure, but now I’m confident. The right person will come when it’s time.", author: "Amelia"},
        { content: "A serious relationship starts with someone truly knowing\nwho I am!", author: "Harper"},
        { content: "I was feeling the pressure, but now I’m confident. The right person will come when it’s time.", author: "Elia"},
      ],
      show: false,
      image: null,
      previewPlayer: null,
      isVideoPlaying: false,
      isVideoInit: false,
      hasScrolled: false,
      observedSections: new Set(),
      firstScrollEventOccurred: false, // 스크롤 이벤트 발생 여부
      firstSectionLogged: false, // 첫 번째 섹션 스크롤 감지
    }
  },
  beforeMount() {
    localStorage.removeItem('UtmReferrer');
    this.setUserData({web_referrer: 'not set'});
    this.setUtmReferrerData('not set');
  },
  mounted() {
    let utmContent = new URLSearchParams(window.location.search).get('utm_content');
    if (utmContent) {
      let webReferrer = decodeURIComponent(utmContent);
      this.utm = webReferrer;
      console.log(this.utm);
      this.$store.dispatch('setUserData', {web_referrer: webReferrer});
      this.$store.dispatch('setUtmReferrerData', webReferrer);
      localStorage.setItem('UtmReferrer', webReferrer);
      console.log('success set utmContent', webReferrer);
    }
    this.$nextTick(() => {
      this.initializePreviewVideo();
    });
    logEvent('lp_into', {
      'web_referrer': this.utm,
    });

    this.$nextTick(() => { // 페이지 로딩이 끝난 이후 실행
      this.initScrollHandling();
    });
  },
  methods: {
    ...mapActions(['setUserData','setUtmReferrerData']),
    goInputPage() {
      logEvent('lp_cta_click', {
        'web_referrer': this.utm,
      })
      this.$router.push(`/tarot_box/input_v2/2?os_type=web&utm_content=${this.utm}`);
    },
    initScrollHandling() {
      const screenElement = document.querySelector('.screen');
      if (screenElement) {
        screenElement.addEventListener('scroll', this.handleScroll);
      }
    },
    initializePreviewVideo() {
      const options = {
        muted: true,
        controls: true,
        loop: true,
        playsinline: true,
        responsive: true,
        fluid: true,
        autoplay: false,
        sources: [{
          src: "https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/mastro%2F480.mp4?alt=media&token=d682e876-67d6-4a86-9091-2c7ab20b4c10",
          type: "video/mp4"
        }]
      };

      this.previewPlayer = videojs(this.$refs.previewVideo, options, () => {
        console.log('Preview video player is ready');
      });
    },
    checkVideoVisibility() {
      if (this.$refs['3_video'] && this.previewPlayer) {
        const videoElement = this.$refs['3_video'];
        const rect = videoElement.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        // 비디오가 화면의 50% 이상 보이는지 확인
        const visiblePercentage = (Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0)) / rect.height;

        if (visiblePercentage > 0.5 && !this.isVideoPlaying) {
          this.previewPlayer.play();
          this.isVideoPlaying = true;
          if(!this.isVideoInit){
            logEvent('lp_preview_video_play', {
              'web_referrer': this.utm,
            })
            this.isVideoInit = true;
          }
        } else if (visiblePercentage <= 0.5 && this.isVideoPlaying) {
          this.previewPlayer.pause();
          this.isVideoPlaying = false;
        }
      }
    },

    handleScroll() {
      // 첫 번째 섹션이 아직 감지되지 않았다면 로그 전송
      if (!this.firstSectionLogged) {
        this.firstSectionLogged = true;
        logEvent('lp_scroll_1_intro', {
          'web_referrer': this.utm,
        });
        this.firstSectionLogged = true;
      }
      this.initIntersectionObserver();
      this.checkVideoVisibility();
    },
    initIntersectionObserver() {
      const observer = new IntersectionObserver(
          entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting) { // 요소가 뷰포트에 보이는가?
                const sectionRef = Object.keys(this.$refs).find(
                    key => this.$refs[key] === entry.target
                );
                // 해당 섹션이 이미 기록되지 않았을 경우
                if (sectionRef && !this.observedSections.has(sectionRef)) {
                  this.observedSections.add(sectionRef); // 섹션 기록
                  // 첫 번째 섹션은 스크롤 발생 후 처리
                  if (sectionRef === '1_intro') {
                    this.firstSectionLogged = true;
                  } else {
                    // 나머지 섹션들은 바로 로그 전송
                    logEvent("lp_scroll_" + sectionRef, {
                      'web_referrer': this.utm,
                    });
                  }
                  // 해당 섹션이 로그된 후 더 이상 관찰하지 않음
                  observer.unobserve(entry.target);
                }
              }
            });
          },
          {
            threshold: 0,
            rootMargin: '0px 0px -66px 0px' // 감지 시점 조정(cta 높이값만큼 늦게 감지됨)
          }
      );
      // 모든 섹션을 관찰 start
      Object.values(this.$refs).forEach(ref => {
        if (ref) {
          observer.observe(ref); // 각 ref를 Observer로 관찰
        }
      });
    },
    logSwipe() {
      logEvent('lp_review_swipe', {
        'web_referrer': this.utm,
      });
    },
    showPreview(idx){
      this.show= true;
      this.image = this.imgList[idx];
    },
    setPreview(img) {
      return {
        backgroundImage: `url(${img})`
      };
    }
  },
  beforeUnmount() {
    document.querySelector('.screen').removeEventListener('scroll', this.handleScroll);
    if (this.previewPlayer) {
      this.previewPlayer.dispose();
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
*{
  margin: 0;
  padding: 0;
}
.screen {
  position: relative;
  width: 100%;
  max-width: 480px;
  height: 100svh;
  overflow-y: auto;
  margin: 0 auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #292929;
}
.screen::-webkit-scrollbar {
  display: none;
}
.sections-container {
  display: flex;
  flex-direction: column;
}
.section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height:fit-content;
  background: #2e2e2e;
  padding-bottom: 52px;
}
.content{
  width: 100%;
  height: fit-content;
  background: #1E1E1E;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content-table {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 60px);
  margin-top: 40px;
  padding: 28px 12px;
  border: 1px solid #EBE0C9;
  background: #292929;
  gap: 20px;
  box-shadow: 3px 3px 0 #EBE0C9;  /* 추가 */
}

.content-table::after {
  content: '';  /* 추가 */
  position: absolute;  /* 추가 */
  z-index: -1;  /* 변경 */
  top: 3px;  /* 변경 */
  left: 3px;  /* 변경 */
  width: 100%;  /* 변경 */
  height: 100%;  /* 변경 */
  background: #EBE0C9;
}

.table-con{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  color: #F8ECD4;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
}
.white-texts{
  color: white;
  gap: 0;
}
.white-texts p{
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.t

.twothird{
  position: relative;
}

.text-con{
  position: relative;
  width: 100%;
  color: white;
  display: grid;
}

.text-con img{
  position: absolute;
}

.text-con img#dot{
  width: 28px;
  bottom: 6px;
  left: 6px;
  z-index: 2;
}

.text-con img#moon{
  width: 50vw;
  max-width: 250px;
  top: -30px;
  right: -90px;
  z-index: 2;
  overflow: hidden;
}

.text-con img#rose{
  width: 100px;
  bottom: -180px;
  left: -20px;
  z-index: 0;
}

.sub-con{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.sub-con h1{
  font-family: "Playfair Display", serif;
  font-weight: 600;
  color: #F8ECD4;
  text-align: center;
  font-size: 24px;
}
.sub-con p{
  color: #F8ECD4;
  font-size: 20px;
  margin-top: 20px;
}

.five{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #292929;
  height: fit-content;
  gap: 20px;
  padding: 20px 0;
  box-sizing: border-box;
}

.five h1{
  font-family: "Playfair Display", serif;
  font-weight: 600;
  color: #F8ECD4;
  font-size: 26px;
}

.title{
  display: grid;
  margin: 0 auto;
  width: 100%;
}
.text-con h1{
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  color: #F8ECD4;
  font-size: 60px;
}

.title-left{
  display: flex;
  margin: 0 auto;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 310px;
  line-height: 50px;
}

.title-right{
  position: relative;
  display: flex;
  padding: 0 40px;
  margin: 0 auto;
  box-sizing: border-box;
  align-items: flex-end;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 310px;
  line-height: 44px;
}

.title-right h1{
  font-size: 40px;
}

.title-left h1{
  font-size: 44px;
}
.text-con h3{
  font-family: "Playfair Display", serif;
  font-size: 18px;
  font-weight: 400;
}
.text {
  display: grid;
  row-gap: 12px;
}
.text-con p{
  box-sizing: border-box;
  width: 100%;
  padding: 0 32px;
  font-size: 20px;
  font-weight: 300;
}
.first{
  background: #292929;
  padding-bottom: 45px;
  box-sizing: border-box;
  overflow-x: hidden;
}
.logo{
  width: 85.02px;
  margin: 73px 0 29px 0;
  display: flex;
  justify-content: center;
}
.scroll-hint {
  height: 24px;
  display: flex;
  align-items: center;
  padding: 0 13px 0 15px;
  margin-bottom: 14px;
  border-radius: 90px;
  background: #D9CEB7;
}
.scroll-hint img{
  height: 24px;
}
.scroll-hint span{
  color: #221D11;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}
.img-box{
  width: 100%;
  height: fit-content;
  position: relative;
}
.img-box img{
  width: 36.3vw;
  max-width: 36.1%;
}
.bg-gr{
  position: absolute;
  width: 100%;
  height: 32.5%;
  bottom: 0;
  background: linear-gradient(180deg, rgba(30, 30, 30, 0.00) 0%, #1E1E1E 36.21%);
}
.img-con .text-con{
  background-color: #1E1E1E;
  margin-bottom: 35px;
  row-gap: 10px;
}
.img-con> .text-con> h1{
  display: grid;
  align-items: end;
}
.img-con{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 24px;
}

.three{
  background: #E4CBCB;
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.three h1, h2{
  font-family: "Playfair Display", serif;
  font-weight: 400;
}

.three h1{
  font-size: 28px;
}

.three h2{
  font-size: 18px;
}



.acc-con{
  width: 100%;
  height: fit-content;
}
.acc{
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  position: relative;
  height: max-content;
}
.acc-header{
  width:fit-content;
  box-sizing: border-box;
  line-height: 32px;
  font-size: 28px;
  padding: 3px 20px;
  border-radius: 90px;
  background: #FFD379;
  position: absolute;
  top:-15px;
  z-index: 10;
}
.acc-body{
  box-sizing: border-box;
  width:100%;
  background-color: #F8ECD4;
  height: fit-content;
  padding: 26px 23px 22px 22px;
  position: relative;
  line-height: 1.5;
  font-size: 23px;
}
.phone-img{
  box-sizing: border-box;
  width: 100%;
  padding: 40px 23px 13px 24px;
}
.phone-img img{
  width: 100%;
}

.phone-text{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 30px 0;
}

.phone-text h1,
.phone-text h2{
  font-family: "Playfair Display", serif;
  font-weight: 400;
}

.phone-text p{
  margin-top: 20px;
  font-size: 20px;
  font-weight: 300;
  color: white;
  text-align: center;
}
.second{
  background: #E9E8E6;
  position: relative;
}
.second > .text-con{
  margin-top: 50px;
}
.second > .text-con  * {
  color: #515151;
}
.second > .text-con  p{
  padding: 0 20px;
}

.list-con{
  width: 100%;
  color: #515151;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 62px;
  margin-top: 25px;
}
.list{
  width: calc(100% - 30px);
  min-width: 300px;
  background-size: cover;
  padding-top: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list img{
  width: 83.3%;
}
.list-con h1{
  font-family: "Playfair Display", serif;
  font-size: 28px;
  font-weight: 500;
}
.slider-con{
  width: 100%;
  height: fit-content;
  padding: 36px 0 35.35px 0;
  background: #292929;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
}
.slide-text {
  position: relative;
  box-sizing: border-box;
  width: calc(100% / 1.1);
  height: fit-content;
  padding: 20px 40px 40px 20px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.40);
  margin-right: 8px;
  flex-shrink: 0;
  background: #F1E0E0;
  color: #292929;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slide-text h4 {
  font-family: "Playfair Display", serif;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
}

.slide-text p {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 16px;
  line-height: 1.4;
}

.slider {
  width: 100%;
  height: 100%;
  padding-left: 21px;
  padding-right: 16px;
  overflow-x: hidden;
}
.third{
  position: relative;
  width: 100%;
  height: fit-content;
  background-color: #1E1E1E;
  background-image: url(@/tarot_box/pages/home/assets/images/solarsystem.png);
  background-repeat: no-repeat;
  background-position: 100% 60%;
  background-size: auto 298px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.table{
  width: calc(100% - 60px);
  margin-top: 42px;
  margin-bottom: 73px;
  object-fit: contain;
}
.third > .text-con{
  margin-top: 66px;
}
.third > .text-con  * {
  color: white;
}
.third > .text-con  h3{
  width: calc(100% - 33px);
  margin: auto;
}
.third > .text-con> h1{
  display: block;
  font-size: 26px;
  width: calc(100% - 60px);
  margin: 0 auto;
}
.third > .text-con  p{
  padding: 0 20px;
  letter-spacing: -0.48px;
}
.fourth{
  background-color: #E9E8E6;
}
.cards-con{
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: calc(100% - 60px);
  margin: 66px auto 43px auto;
}
.card{
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 12px;
  justify-content: space-between;
  border-radius: 4px;
  background: #EFEFEF;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
  z-index: 1;
}
.card-text{
  width: 100%;
  font-family: "Playfair Display", serif;
  word-break: keep-all;
  color: #000;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.64px;
  text-align: start;
}
.auth{
  color: #515151;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.42px;
}
.notice{
  position: relative;
  box-sizing: border-box;
  background-color: #2E2E2E;
  padding: 32px 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 20px;
}
.notice h1{
  font-family: "Playfair Display", serif;
  color: #C8C8C8;
  font-size: 18px;
  font-weight: 400;
}
.notice ul{
  width: calc(100% - 18px);
  text-align: start;
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.notice li{
  color: #9B9B9B;
  font-weight: 400;
  letter-spacing: -0.36px;
  font-size: 14px;
}
.footer{
  box-sizing: border-box;
  width: 100%;
  height: 183px;
  background: #1E1E1E;
  padding: 32px 20px calc(10.56svh + 23px) 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
}
.footer> div{
  color: #797979;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.36px;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
  row-gap: 2px;
  margin-top: 12px;
}
.footer .link{
  margin-top:23px;
  display: flex;
  flex-direction: row;
  column-gap: 5px;
}
.link a{
  color: #797979;
  font-size: 14px;
}
.footer img{
  width: 96px;
}
.stone{
  width: 112px;
  height: 74px;
  position: absolute;
  top: -21px;
  right: 29px;
}
.modal{
  width: 100vw;
  height: 100svh;
  background: rgba(0, 0, 0, 0.80);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: fixed;
  top:0;
  left: 0;
}
.exit{
  position: absolute;
  top: 24px;
  right: 22px;
  width: 24px;
}
.preview{
  width: calc(100% - 64px);
  max-width: 460px;
}
.cta{
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  padding: 12px 20px;
  background: rgba(0, 0, 0, 0.50);
  box-shadow: 0 -3px 8px 0 rgba(0, 0, 0, 0.60);
  display: flex;
  justify-content: center;
  height: 66px;
  box-sizing: border-box;
  border: none;
  z-index: 100;
}
button{
  width: 100%;
  height: 100%;
  color: #000000;
  font-size: 18px;
  font-weight: 800;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #FFC653 0%, #FF9C9C 100%);
  z-index: 100;
}

.video-container {
  width: 100%;
  max-width: 480px;
  margin: 20px auto;
}
</style>
