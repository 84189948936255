<template>
  <div class="item-contents">
    <div class="upper">
      <div class="badge" :class="{'interpreting': item.status === 'paid_and_waiting', 'requested': item.status === 'requested'}">
        <div class="new" v-if="item.tags === 'new'">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="6" fill="#FF6666"/>
            <path d="M8.23643 3.27271V8.70168H7.11783L4.93654 5.06248V8.70168H3.81793V3.27271H4.93654L7.12155 6.91564V3.27271H8.23643Z" fill="white"/>
          </svg>
        </div>
        <div class="loading-indicator" v-if="item.status === 'paid_and_waiting'">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <span class="title5" :class="{'interpreting': item.status === 'paid_and_waiting', 'requested': item.status === 'requested'}">
        {{verifyInterpretStatus(item.status)}}
      </span>
      </div>
      <div class="item-info">
        <span class="title4" v-html="parseText(item.tarot_box_title)"/>
      </div>
    </div>
    <div class="lower">
      <div class="date">
        <span class="text3">{{formatDate(item.created_at)}}</span>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: "MyTarotItemContent",
  components: {},
  props: {
    item: {
      type: Object,
    }
  },
  computed: {
  },
  methods: {
    verifyInterpretStatus(status) {
      if (status === 'paid_and_waiting') {
        return '해석 중'
      } else if (status === 'requested'){
        return '해석 대기 중'
      } else {
        return '해석 완료'
      }
    },
    parseText(text) {
      if (text) {
        return text.replace(/\\n/g, " ").substring(0, 42) + (text.length > 42 ? "..." : "");
      } else {
        return "";
      }
    },
    formatDate(date) {
      if (!date) {
        return ''; // 날짜 값이 유효하지 않은 경우 빈 문자열 반환
      }

      const newDate = new Date(date);
      const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
      let formattedDate = newDate.toLocaleDateString('ko-KR', options);

      // 'toLocaleDateString'의 반환값이 예상과 다르게 분리되지 않는 경우에 대비
      if (!formattedDate.includes(', ')) {
        return formattedDate; // 기본 형식 반환
      }

      const [ymd, time] = formattedDate.split(', ');
      const [ampm, hourMinute] = time.split(' ');
      const rearrangedDate = ymd.replace(/\//g, '.');

      return `${rearrangedDate} ${ampm} ${hourMinute}`;
    },

  },
}
</script>

<style scoped>
.upper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
}
.badge{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  margin-right: 8px;
  border-radius: 50px;
  background: var(--Color-palette-Primary-Lighten2, #68769E);
}

.badge.interpreting{
  background: var(--Grayscale-Gray10, #ECECEC);}

.title5.interpreting{
  color: var(--Grayscale-Gray3, #333);
}

.badge.requested {
  background: var(--Grayscale-Gray7, #FFF7E5);
}

.title5.requested {
  color: var(--Grayscale-Gray12, #E67A00);
}

.new{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px 0 0;
  height: 12px; /* 변경됨 */
  width: 12px; /* 변경됨 */

}
.title5{
  color: var(--Grayscale-Gray12);
  font-size: 12px;
}

.text3{
  color: var(--Grayscale-Gray5, #777);
}

.loading-indicator {
  display: inline-block;
  position: relative;
  width: 12px; /* 변경됨 */
  height: 12px; /* 변경됨 */
  margin-right: 3px;
}
.loading-indicator div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px; /* 변경됨 */
  height: 10px; /* 변경됨 */
  margin: 1px; /* 변경됨 */
  border: 1px solid var(--Color-palette-Primary-Default); /* 변경됨 */
  border-radius: 50%;
  animation: loading-indicator 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--Color-palette-Primary-Default) transparent transparent transparent;
}
.loading-indicator div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-indicator div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-indicator div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loading-indicator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.item-contents {
  display: flex;
  flex-direction: column;
  height: 91px;
  justify-content: space-between;
}

.item-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  align-items: flex-start;
  padding-left: 3px;
  padding-right: 10px;
  box-sizing: border-box;
}

.date{
  padding-left: 3px;
  box-sizing: border-box;
}

.title4, .caption1 {
  white-space: normal;
  overflow-wrap: break-word;
  word-break: keep-all;
  line-height: 120% !important;
  color: var(--Grayscale-Gray4, #555);
}

.date{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
</style>