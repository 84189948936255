<template>
  <div class="sheet-container">
    <div class="sheet-title">
      <strong>스프레드 Tip</strong>
      <img :src="require('@/tarot_box/pages/box_result_v2/assets/xbutton.svg')" alt="close" @click="$emit('close')"/>
    </div>
    <div class="sheet-content">
      <img :src="require('../../assets/main-loading/tarot_guide.png')" alt="tarot_guide" style="width: 100%; height: auto;"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TarotGuideComponent'
}
</script>

<style scoped>
.sheet-container{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  padding: 12px 24px 76px 24px;
  box-sizing: border-box;
  position: relative;
}

.sheet-title{
  font-family: "SUIT Variable", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px 20px 10px 20px;
  box-sizing: border-box;
  background: white;
  z-index: 9999;
}

.sheet-content{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;

  margin-top: 60px;
}
</style>