<template>
  <div class="content">
    <div class="section">
      <div class="header title3" style="margin-bottom: 24px;">
        Terms and Conditions
      </div>
      <label for="allAgree" class="custom-checkbox title4" style="margin-bottom: 10px">
        <input type="checkbox" id="allAgree" :checked="isAllAgree" @change="toggleAllCheckboxes" class="hidden-checkbox">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13" cy="13" r="12" :fill="isAllAgree ? '#333333' : '#D9D9D9'"/>
            <path d="M9 12L12.9048 17L17.6667 9" stroke="white" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        Agree to ALL
      </label><!-- 하단 체크박스 -->
      <div>
        <label class="custom-checkbox text1">
          <input type="checkbox" :checked="agreements.service" @change="() => updateAgreement('service')" class="hidden-checkbox">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 12L12.9048 17L17.6667 9" :stroke="agreements.service ? '#333333' : '#D9D9D9'" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          Terms of Service*
          <svg style="margin-left: 6px;" @click="openInNewTab('https://terms.1zlabs.com/mastro-terms-of-service')"
               width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.86 6.016L1.666 11.21L0.98 10.524L5.474 6.016L0.98 1.522L1.666 0.821999L6.86 6.016Z" fill="#999999"/>
          </svg>
        </label>
      </div>
      <div>
        <label class="custom-checkbox text1">
          <input type="checkbox" :checked="agreements.privacy" @change="() => updateAgreement('privacy')" class="hidden-checkbox">
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 12L12.9048 17L17.6667 9" :stroke="agreements.privacy ? '#333333' : '#D9D9D9'" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          Privacy Policy*
          <svg style="margin-left: 6px;" @click="openInNewTab('https://terms.1zlabs.com/mastro-terms-of-service/privacy-policy')"
               width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.86 6.016L1.666 11.21L0.98 10.524L5.474 6.016L0.98 1.522L1.666 0.821999L6.86 6.016Z" fill="#999999"/>
          </svg>
        </label>
      </div>

      <div class="disclaimer-section">
        <img id="disclaimer-image" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/mastro%2Fmail_image.png?alt=media&token=80c6ad7f-bce2-4841-9c84-1b7a6d28f910" alt="disclaimer">
        <div class="disclaimer text3">
          You need to agree to the terms of service so we can notify you by email once the interpretation is complete.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {logEvent} from "@/tarot_box/helper/native_api";
import {mapGetters} from "vuex";

export default ({
  name: "CheckoutConsentForm",
  data() {
    return {
      agreements: {
        service: false,
        privacy: false,
      },
      isAllAgree: false,
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    if(status && status === 'fail'){
      this.agreements.service = true;
      this.agreements.privacy = true;
      this.isAllAgree = true;
    }
  },
  emits: ['update:agreements', 'update:isAllAgree'],
  computed:{
    ...mapGetters(['utmReferrer'])
  },
  methods: {
    toggleAllCheckboxes() {
      if(!this.isAllAgree){
        logEvent('purchase_allagree_click', {'web_referrer': this.utmReferrer})
      }
      this.isAllAgree = !this.isAllAgree;
      this.agreements.service = this.isAllAgree;
      this.agreements.privacy = this.isAllAgree;
      this.$emit('update:agreements', this.agreements);
      this.$emit('update:isAllAgree', this.isAllAgree);
    },
    updateAgreement(type) {
      this.agreements[type] = !this.agreements[type];
      if(this.agreements.service === true && this.agreements.privacy === true){
        this.isAllAgree = true;
      } else {
        this.isAllAgree = false;
      }
      this.$emit('update:agreements', this.agreements);
      this.$emit('update:isAllAgree', this.agreements.service && this.agreements.privacy);
    },
    openInNewTab(url) {
      if (url) {
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
          newWindow.focus();
        } else {
          console.error('Failed to open the new tab. The popup might have been blocked.');
        }
      } else {
        console.error('Invalid URL:', url);
      }
    }
  }
})
</script>



<style scoped>
span {
  margin: 0 10px 0 0 !important;
}

.content {
  width: 100%;
  overflow-y: auto; /* 내용이 넘칠 경우 스크롤 허용 */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

}
.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 16px 24px;
  box-sizing: border-box;
}

.hidden-checkbox {
  display: none;
}

.hidden-checkbox:checked + .checkbox-circle::after {
  opacity: 1;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-size: 14px;
  margin: 6px 0;
  gap: 8px;
}

/* 체크 상태일 때의 스타일 */
.checkbox-circle::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--Color-palette-Primary-Default); /* primary color를 변수로 지정하거나 직접 색상 코드 사용 */
  opacity: 0;
  transition: opacity 0.2s;
}

.checkbox-icon{
  margin-right: 6px;
}

.disclaimer-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 14px 12px;
  margin-top: 12px;
  margin-bottom: 34px;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
  background: var(--Grayscale-Gray10);
}

#disclaimer-image {
  width: 42px;
  height: 42px;
}
</style>
