<template>
  <input
      :value="modelValue"
      :placeholder="inputItem.options.placeholder"
      :maxlength="maxLength"
      type="text"
      class="text-input"
      @input="updateValue"
      @keyup.enter="submitAnswer"
      @blur="submitAnswer"
      ref="input">
</template>

<script>
export default {
  name: "TextInput",
  props: {
    inputItem: {
      type: Object,
      required: true
    },
    modelValue: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 20
    }
  },
  methods: {
    updateValue(event) {
      const inputValue = event.target.value;
      const sanitizedValue = this.sanitizeInput(inputValue);

      if (inputValue !== sanitizedValue) {
        event.target.value = sanitizedValue;
      }

      this.$emit('focus');
      this.$emit('update:modelValue', sanitizedValue);
    },
    submitAnswer() {
      if (this.modelValue.trim()) {
        this.$emit('submitAnswer', {
          ...this.inputItem,
          value: this.modelValue,
          inputFormType: this.inputItem.inputFormType,
          inputFormSubtype: this.inputItem.inputFormSubtype
        });
        this.$refs.input.blur();
      } else{
        this.$emit('invalidInput');
      }
    },
    sanitizeInput(input) {
      return input.replace(/[^a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣\s.]/g, '');
    },
    focus(){
      this.$refs.input.focus();
    }
  },
  mounted() {
    this.$refs.input.focus();
  },
  emits: ['update:modelValue', 'submitAnswer', 'focus', 'invalidInput']
}
</script>

<style scoped>
.text-input {
  width: 100%;
  padding: 24px 16px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  background: var(--Grayscale-Gray11, #F5F5F5);
  border-bottom: none;
}

.text-input:focus {
  outline: 2px solid var(--Color-palette-Primary-Default);
}

.text-input::placeholder {
  font-size: 18px;
  color: var(--Grayscale-Gray8, #8C8C8C);
}
</style>