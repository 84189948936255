<template>
  <div class="skeleton-wrapper">
    <div class="skeleton-grid">
      <div class="skeleton-field skeleton-card-number"></div>
      <div class="skeleton-field skeleton-expiry"></div>
      <div class="skeleton-field skeleton-cvc"></div>
    </div>
    <div class="skeleton-field skeleton-country"></div>
  </div>
</template>

<script>
export default {
  name: 'StripeFrameSkeleton'
}
</script>

<style scoped>
.skeleton-wrapper {
  width: 100%;
  max-width: 290px;
  padding: 0 20px;
  background-color: #ffffff;
  border-radius: 12px;
}

.skeleton-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px 12px;
  margin-bottom: 30px;
}

.skeleton-field {
  height: 52px;
  background-color: #e8e8e8;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
}

.skeleton-field::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
}

.skeleton-card-number {
  grid-column: span 4;
}

.skeleton-cvc {
  grid-column: span 2;
}

.skeleton-expiry {
  grid-column: span 2;
}

.skeleton-country {
  height: 52px;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
