<template>
   <div class="top-bar" v-safe-area-top>
     <svg  v-if="!isShare" @click="closeButtonClick" id="back-button" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M20 21.3288L13.603 27.7261C13.4284 27.9005 13.2089 27.9898 12.9445 27.994C12.6803 27.998 12.4568 27.9087 12.2739 27.7261C12.0913 27.5432 12 27.3217 12 27.0615C12 26.8013 12.0913 26.5798 12.2739 26.397L18.6712 20L12.2739 13.603C12.0995 13.4284 12.0102 13.2089 12.006 12.9445C12.002 12.6803 12.0913 12.4568 12.2739 12.2739C12.4568 12.0913 12.6783 12 12.9385 12C13.1987 12 13.4202 12.0913 13.603 12.2739L20 18.6712L26.397 12.2739C26.5716 12.0995 26.7911 12.0102 27.0555 12.006C27.3197 12.002 27.5432 12.0913 27.7261 12.2739C27.9087 12.4568 28 12.6783 28 12.9385C28 13.1987 27.9087 13.4202 27.7261 13.603L21.3288 20L27.7261 26.397C27.9005 26.5716 27.9898 26.7911 27.994 27.0555C27.998 27.3197 27.9087 27.5432 27.7261 27.7261C27.5432 27.9087 27.3217 28 27.0615 28C26.8013 28 26.5798 27.9087 26.397 27.7261L20 21.3288Z" fill="#333333"/>
     </svg>

     <svg v-if="isShare" @click="goHome" width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M22 10.9141L12 2L2 10.9141" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
       <path d="M3.4541 12.0286V22.5857H9.8186V16.7217H14.1812V22.5857H20.5457V12.0286" stroke="#333333" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
     </svg>

     <svg @click="openShareMenu" id="share-button" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M5 22C4.45 22 3.97917 21.8096 3.5875 21.4288C3.19583 21.048 3 20.5903 3 20.0556V9.36111C3 8.82639 3.19583 8.36863 3.5875 7.98785C3.97917 7.60706 4.45 7.41667 5 7.41667H8C8.28333 7.41667 8.52083 7.50984 8.7125 7.69618C8.90417 7.88252 9 8.11343 9 8.38889C9 8.66435 8.90417 8.89525 8.7125 9.0816C8.52083 9.26794 8.28333 9.36111 8 9.36111H5V20.0556H19V9.36111H16C15.7167 9.36111 15.4792 9.26794 15.2875 9.0816C15.0958 8.89525 15 8.66435 15 8.38889C15 8.11343 15.0958 7.88252 15.2875 7.69618C15.4792 7.50984 15.7167 7.41667 16 7.41667H19C19.55 7.41667 20.0208 7.60706 20.4125 7.98785C20.8042 8.36863 21 8.82639 21 9.36111V20.0556C21 20.5903 20.8042 21.048 20.4125 21.4288C20.0208 21.8096 19.55 22 19 22H5ZM11 4.32986L10.1 5.20486C9.9 5.39931 9.66667 5.49248 9.4 5.48438C9.13333 5.47627 8.9 5.375 8.7 5.18056C8.51667 4.98611 8.42083 4.75926 8.4125 4.5C8.40417 4.24074 8.5 4.01389 8.7 3.81944L11.3 1.29167C11.5 1.09722 11.7333 1 12 1C12.2667 1 12.5 1.09722 12.7 1.29167L15.3 3.81944C15.4833 3.99769 15.575 4.22049 15.575 4.48785C15.575 4.75521 15.4833 4.98611 15.3 5.18056C15.1 5.375 14.8625 5.47222 14.5875 5.47222C14.3125 5.47222 14.075 5.375 13.875 5.18056L13 4.32986V14.2222C13 14.4977 12.9042 14.7286 12.7125 14.9149C12.5208 15.1013 12.2833 15.1944 12 15.1944C11.7167 15.1944 11.4792 15.1013 11.2875 14.9149C11.0958 14.7286 11 14.4977 11 14.2222V4.32986Z" fill="#333333"/>
     </svg>
   </div>
</template>

<script>
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "CoverImage",
  emits: ['open-share-menu', 'close-button-click'],
  props: {
    isShare: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    openShareMenu() {
      logEvent('result_UI_share_click', {});
      this.$emit('open-share-menu');
    },
    closeButtonClick() {
      this.$emit('close-button-click');
    },
    goHome() {
      let queryParams = { ...this.$route.query };
      if (queryParams.os_type === 'web') {
        queryParams.is_web = 'true';
        delete queryParams.os_type;
      }
      this.$router.push({ path: '/tarot_box/home', query: queryParams });
    },
  }
}
</script>

<style scoped>
.top-bar{
  width: 100vw;
  padding: 6px 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  background: white;
  border-bottom: 1px solid var(--Grayscale-Gray10, #ECECEC);
}

#back-button{
  transform: translateX(-8px);
}

</style>