<template>
  <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
    <div class="overlay" @click="backgroundClick"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          정말 삭제하시겠어요?
        </span>
        <span class="text2" style="margin-bottom: 40px;">
          삭제 후 취소 및 복원이
          <span style="color: var(--Color-palette-Primary-Lighten1, #2D3E73)">
            불가능
          </span>
          합니다.
        </span>
      </div>
      <div class="popup-content">
        <svg width="216" height="130" viewBox="0 0 216 130" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_40_2162)">
            <path d="M141.261 47.6338C140.951 50.3126 139.404 54.6196 136.283 55.9787C139.404 57.3378 140.951 61.6383 141.261 64.3237C141.57 61.6449 143.118 57.3378 146.239 55.9787C143.118 54.6196 141.57 50.3191 141.261 47.6338Z" fill="#1E2352"/>
            <path d="M141.261 59.6227C140.649 58.2899 139.806 56.9768 138.673 55.9788C139.799 54.9808 140.649 53.6677 141.261 52.3348C141.873 53.6677 142.716 54.9808 143.842 55.9788C142.716 56.9768 141.867 58.2899 141.261 59.6227Z" fill="#1E2352"/>
            <path d="M54.1959 83.7712C53.8865 86.45 52.3391 90.7571 49.2181 92.1162C52.3391 93.4753 53.8865 97.7758 54.1959 100.461C54.5054 97.7824 56.0527 93.4753 59.1737 92.1162C56.0527 90.7571 54.5054 86.4566 54.1959 83.7712Z" fill="#1E2352"/>
            <path d="M54.1959 95.7601C53.5835 94.4272 52.7407 93.1141 51.6082 92.1161C52.7341 91.1181 53.5835 89.805 54.1959 88.4722C54.8082 89.805 55.651 91.1181 56.7835 92.1161C55.6576 93.1141 54.8082 94.4272 54.1959 95.7601Z" fill="#1E2352"/>
            <path d="M104.013 25.6127C102.993 25.6127 102.163 26.4399 102.163 27.4576C102.163 28.4753 102.993 29.3026 104.013 29.3026C105.034 29.3026 105.863 28.4753 105.863 27.4576C105.863 26.4399 105.034 25.6127 104.013 25.6127Z" fill="#1E2352"/>
            <path d="M104.013 27.9828C103.717 27.9828 103.48 27.7465 103.48 27.451C103.48 27.1556 103.717 26.9192 104.013 26.9192C104.309 26.9192 104.547 27.1556 104.547 27.451C104.547 27.7465 104.309 27.9828 104.013 27.9828Z" fill="#1E2352"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M135.006 16.3289C135.006 14.6087 136.408 13.2102 138.133 13.2102C139.859 13.2102 141.261 14.6087 141.261 16.3289C141.261 18.0491 139.859 19.4476 138.133 19.4476C136.408 19.4476 135.006 18.0491 135.006 16.3289Z" fill="#68769E"/>
            <path d="M93.8469 113.993C94.6506 113.993 95.3021 113.343 95.3021 112.542C95.3021 111.741 94.6506 111.091 93.8469 111.091C93.0433 111.091 92.3918 111.741 92.3918 112.542C92.3918 113.343 93.0433 113.993 93.8469 113.993Z" fill="#68769E"/>
            <path d="M81.949 104.256C81.1852 104.256 80.5663 104.873 80.5663 105.635C80.5663 106.397 81.1852 107.014 81.949 107.014C82.7128 107.014 83.3317 106.397 83.3317 105.635C83.3317 104.873 82.7128 104.256 81.949 104.256Z" fill="#1E2352"/>
            <path d="M81.949 105.701C81.9095 105.701 81.8831 105.668 81.8831 105.635C81.8831 105.602 81.916 105.569 81.949 105.569C81.9819 105.569 82.0148 105.602 82.0148 105.635C82.0148 105.668 81.9819 105.701 81.949 105.701Z" fill="#1E2352"/>
            <path d="M124.181 45.7626H122.173V128.667H124.181V45.7626Z" fill="#1E2352"/>
            <path d="M39.5786 128.155V46.9969C39.5786 46.4323 40.0263 45.9792 40.586 45.9792C41.1391 45.9792 41.5934 46.4323 41.5934 46.9969V128.162C41.5934 128.726 41.1457 129.179 40.586 129.179C40.0329 129.179 39.5786 128.726 39.5786 128.162V128.155Z" fill="#1E2352"/>
            <path d="M167.934 4.05762H165.926V87.9733H167.934V4.05762Z" fill="#1E2352"/>
            <path d="M85.2741 5.06216C85.2741 4.50737 84.8245 4.05762 84.27 4.05762C83.7154 4.05762 83.2659 4.50737 83.2659 5.06216V85.6556C83.2659 86.2104 83.7154 86.6601 84.27 86.6601C84.8245 86.6601 85.2741 86.2104 85.2741 85.6556V5.06216Z" fill="#1E2352"/>
            <path d="M92.5169 46.7671C92.5169 46.2156 92.9646 45.7626 93.5243 45.7626H122.377C122.634 45.7626 122.884 45.6641 123.068 45.4868L162.614 7.79338C163.266 7.16964 162.825 6.06661 161.923 6.06661H85.0831C84.8264 6.06661 84.5761 6.1651 84.3918 6.34237L44.8461 44.0424C44.1943 44.6661 44.6354 45.7691 45.5375 45.7691H75.0288C75.5819 45.7691 76.0362 46.2156 76.0362 46.7737C76.0362 47.3252 75.5885 47.7782 75.0288 47.7782H40.5202C40.1054 47.7782 39.7432 47.5287 39.5852 47.1479C39.4272 46.7671 39.5259 46.3338 39.8222 46.0515L83.5753 4.33984C83.7663 4.16257 84.0099 4.06409 84.2667 4.06409H166.92C167.335 4.06409 167.697 4.31358 167.855 4.69439C168.013 5.0752 167.914 5.50853 167.618 5.79085L123.865 47.4959C123.674 47.6732 123.43 47.7717 123.174 47.7717H93.5177C92.9646 47.7717 92.5103 47.3252 92.5103 46.7671H92.5169Z" fill="#1E2352"/>
            <path d="M40.5202 130C40.1054 130 39.7432 129.751 39.5852 129.37C39.4338 128.989 39.5259 128.556 39.8222 128.273L83.5753 86.5682C83.7663 86.391 84.0099 86.2925 84.2667 86.2925H113.923C114.476 86.2925 114.93 86.739 114.93 87.297C114.93 87.8486 114.482 88.3016 113.923 88.3016H85.07C84.8132 88.3016 84.563 88.4001 84.3786 88.5773L44.8329 126.277C44.1811 126.901 44.6222 128.004 45.5243 128.004H122.364C122.621 128.004 122.871 127.906 123.055 127.728L162.601 90.0283C163.253 89.4046 162.812 88.3016 161.909 88.3016H132.412C131.858 88.3016 131.404 87.8551 131.404 87.297C131.404 86.7455 131.852 86.2925 132.412 86.2925H166.914C167.328 86.2925 167.691 86.542 167.849 86.9228C168.007 87.3036 167.908 87.7369 167.612 88.0193L123.858 129.724C123.668 129.902 123.424 130 123.167 130H40.5202Z" fill="#1E2352"/>
            <path d="M103.77 77.0217C109.25 77.0217 113.692 72.5918 113.692 67.1272C113.692 61.6627 109.25 57.2328 103.77 57.2328C98.2894 57.2328 93.8469 61.6627 93.8469 67.1272C93.8469 72.5918 98.2894 77.0217 103.77 77.0217Z" fill="#68769E"/>
            <path d="M189.017 118.714C184.836 118.714 180.155 118.438 175.091 117.9C152.757 115.516 124.76 108.064 96.2568 96.9222C67.7531 85.7803 42.1399 72.2748 24.1317 58.894C0.632103 41.4293 -1.58024 30.8521 0.697945 25.048C3.21317 18.6531 12.3918 15.2783 27.2593 15.2783C36.9119 15.2783 48.6453 16.7096 62.1366 19.5263C62.5119 19.6051 62.8872 19.6839 63.2691 19.7627C63.8025 19.8743 64.1449 20.4061 64.0263 20.9445C63.9078 21.4763 63.3811 21.8046 62.8543 21.693C62.479 21.6142 62.1037 21.5354 61.7284 21.4566C48.665 18.7253 36.6815 17.2874 27.0815 17.2874C13.2016 17.2874 4.71441 20.2223 2.5284 25.7702C-0.296294 32.9596 8 44.4561 25.2971 57.3117C43.1737 70.594 68.6222 84.0076 96.9613 95.0904C125.3 106.167 153.113 113.573 175.282 115.943C180.089 116.455 184.606 116.718 188.701 116.718C202.733 116.718 211.305 113.783 213.485 108.235C217.337 98.4389 199.361 80.042 168.606 62.144C168.138 61.8748 167.974 61.2773 168.244 60.8046C168.514 60.3318 169.126 60.1677 169.6 60.4435C194.37 74.8879 220.504 95.7995 215.328 108.957C212.82 115.345 203.714 118.72 189.004 118.72L189.017 118.714Z" fill="#1E2352"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.3105 16.3288C23.4143 13.6697 26.4431 5.24596 27.0488 0C27.6546 5.24596 30.6834 13.6697 36.7871 16.3288C30.6834 18.9879 27.6546 27.4116 27.0488 32.6576C26.4431 27.4116 23.4143 18.9879 17.3105 16.3288Z" fill="#68769E"/>
          </g>
          <defs>
            <clipPath id="clip0_40_2162">
              <rect width="216" height="130" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </div>

      <div class="popup-footer">
        <div class="btn" @click="handleCancel">
          취소하기
        </div>
        <div class="btn" @click="handleDelete(item.id)" style="background: var(--Color-palette-Primary-Default)">
          삭제하기
        </div>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: "DeleteConfirmPopup",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    closeConfirmPopupCallback: {
      type: Function,
    },
    item: {
      type: Object,
    },
  },
  emits: ['deleteConfirmed'],
  methods: {
    backgroundClick() {
      this.closeConfirmPopupCallback();
    },
    handleCancel() {
      this.closeConfirmPopupCallback();
    },
    async handleDelete(id) {
      this.$emit('deleteConfirmed', id);
      this.closeConfirmPopupCallback();
    }
  }
}
</script>

<style>
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.delete-popup-container {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: calc( 100vw - 40px);
  padding: 52px 0 30px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.popup-header{
  display: flex;
  flex-direction: column;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 50px 16px 0 16px;
  justify-content: space-between;
}

.popup-content{
  margin-bottom: 20px;
}

.btn{
  flex: 1;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Grayscale-Gray9);
  color: var(--Grayscale-Gray12);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}
</style>