import firebase from "firebase/compat/app";
import "firebase/compat/remote-config";

firebase.initializeApp({
    apiKey: "AIzaSyCzKS7d9DJOiumL_66hGi4zXwDVAt3Ytjo",
    authDomain: "tarot-gpt.firebaseapp.com",
    projectId: "tarot-gpt",
    storageBucket: "tarot-gpt.appspot.com",
    messagingSenderId: "444234357574",
    appId: "1:444234357574:web:40b7dfcf468be231f9a347",
    measurementId: "G-QMMFE99XLD"
})

export const remoteConfig = firebase.remoteConfig()
remoteConfig.defaultConfig = {
    "home_box_type": "webview",
    "home_webview_url_v2":"https://my-tarot-web.web.app/tarot_box/home",
    "home_webview_url": "https://my-tarot-web.web.app",
    "my_tarot_history_webview_url": "https://my-tarot-web.web.app/tarot_box/mytarot",
    "add_mate_btn_image_url":
        "https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/dev%2Fadd_mate.png?alt=media&token=da3f9eed-3101-44b2-b221-4c84ae68b677&_gl=1*3u9trq*_ga*MTY5MzI4MzM2Ny4xNjQzODY4OTQ5*_ga_CW55HF8NVT*MTY5Njk5OTY0Mi4yMS4xLjE2OTY5OTk2ODEuMjEuMC4w",
    "ask_template_name": "my_tarot_pick_spreads",
    "buddy_tarot_question_list":
        '"buddy_tarot_question_list":"{"kr":["우리는 궁합이 어떤가요?","우리는 전생에 어떤 사이였을까요?","우리는 운명적인 사이일까?"]}',
    "buddy_tarot_question_list_v2":
        '["우리는 궁합이 어떤가요?","우리는 전생에 어떤 사이였을까요?","우리는 운명적인 사이일까?"]',
    "chat_banner":
        "https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/dev%2Fmate_top_banner.png?alt=media&token=b6824b24-1d37-455a-bfb0-37908230fb87&_gl=1*12rdh8l*_ga*MTY5MzI4MzM2Ny4xNjQzODY4OTQ5*_ga_CW55HF8NVT*MTY5NzA5MTg1NS4yNC4xLjE2OTcwOTE4NjYuNDkuMC4w",
    "chat_message_write_delay": 70,
    "chat_promotion_image_url":
        "https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/dev%2Fmate_chat_promotion.jpg?alt=media&token=5bcf7a99-e670-4046-9f3c-e16c6399e548&_gl=1*1ov1dx5*_ga*MTY5MzI4MzM2Ny4xNjQzODY4OTQ5*_ga_CW55HF8NVT*MTY5NzA5Njc5Mi4yNS4xLjE2OTcwOTY3OTguNTQuMC4w",
    "daily_fortune_dialog_banner_url":
        "https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/%ED%8C%9D%EC%97%85%2Fdaliyluky_popup.png?alt=media&token=4a2d0e6a-d1f3-4ba9-9a74-c01ca32439cd",
    "default_api_url": "https://api.meta-play.co.kr/",
    "finish_signup_tab_index": 1,
    "default_tab_index": 2,
    "force_update_version": "3.0.0",
    "guide_button_key_map": '[{"key":"오늘운세","msg":"오늘 운세 알려줘"},{"key":"금전운","msg":"오늘 나의 금전운 어때?"},{"key":"애정운","msg":"오늘 나의 애정운 어때?"},{"key":"재회운","msg":"재회 가능성 있을까?"},{"key":"속마음","msg":"그 사람의 속마음을 알고싶어"},{"key":"선택고민","msg":"A와 B중 어떤 선택이 좋을까?"}]',
    "invite_friend_banner":
        "https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/dev%2Fsample.png?alt=media&token=34fc33d8-ea15-42ee-8710-215fdf2d1d7f",
    "enable_daily_fortune_dialog_banner": true,
    "is_using_friend_invitation": true,
    "is_hiding_tarotchat": false,
    "mate_chat_domain_url": "https://my-tarot-web.web.app",
    "my_page_invite_text": "친구 초대하고",
    "my_page_invite_highlight_text": "7,000원 받기",
    "paywall_banner_list":"{\"android\": [], \"ios\": []}",
    "re_ask_template_name": "my_tarot_additional_result",
    "result_template_name": "my_tarot_result_spreads",
    "shouldUpdateLink": "https://mytarot.page.link/update",
    "sign_up_invite_desc": "친구와 내가 7,000원 상당 클로버를 받을 수 있어요!",
    "subscription_paywall_banner_url":
"https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/dev%2Fsubscription_paywall_description.png?alt=media&token=25b4c418-16dd-46d3-8acf-5522290905d4&_gl=1*1741nyz*_ga*MTY5MzI4MzM2Ny4xNjQzODY4OTQ5*_ga_CW55HF8NVT*MTY5NjkzNDkzMS4yMC4xLjE2OTY5MzQ5MzQuNTcuMC4w",
    "subscription_paywall_year_button_url":
"https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/dev%2Fsubscription_paywall_year.png?alt=media&token=cfebf474-15e2-4408-87a8-54aa115c1444&_gl=1*13m8j3d*_ga*MTY5MzI4MzM2Ny4xNjQzODY4OTQ5*_ga_CW55HF8NVT*MTY5NjkzNDkzMS4yMC4xLjE2OTY5MzU3MzQuMjQuMC4w",
    "subscription_paywall_month_button_url":
"https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/dev%2Fsubscription_paywall_month.png?alt=media&token=3302dab7-fd30-4b93-be3e-b6102ae8acc6&_gl=1*1s77ewk*_ga*MTY5MzI4MzM2Ny4xNjQzODY4OTQ5*_ga_CW55HF8NVT*MTY5NjkzNDkzMS4yMC4xLjE2OTY5MzU3MTQuNDQuMC4w",
    "subscription_product_id_list":
"[{'group':0,'platform':'android','product_id':'m_mate_01','price':'20900','period':'monthly'},{'group':1,'platform':'android','product_id':'m_mate_02','price':'20900','period':'monthly'},{'group':2,'platform':'android','product_id':'m_mate_03','price':'20900','period':'monthly'},{'group':3,'platform':'android','product_id':'m_mate_04','price':'20900','period':'monthly'},{'group':0,'platform':'ios','product_id':'Mate_A_Monthly','price':'20900','period':'monthly'},{'group':1,'platform':'ios','product_id':'Mate_B_Monthly','price':'20900','period':'monthly'},{'group':2,'platform':'ios','product_id':'Mate_C_Monthly','price':'20900','period':'monthly'},{'group':3,'platform':'ios','product_id':'Mate_D_Monthly','price':'20900','period':'monthly'},{'group':0,'platform':'android','product_id':'y_mate_05','price':'175000','period':'yearly'},{'group':1,'platform':'android','product_id':'y_mate_06','price':'175000','period':'yearly'},{'group':2,'platform':'android','product_id':'y_mate_07','price':'175000','period':'yearly'},{'group':3,'platform':'android','product_id':'y_mate_08','price':'175000','period':'yearly'},{'group':0,'platform':'ios','product_id':'Mate_A_Yearly','price':'175000','period':'yearly'},{'group':1,'platform':'ios','product_id':'Mate_B_Yearly','price':'175000','period':'yearly'},{'group':2,'platform':'ios','product_id':'Mate_C_Yearly','price':'175000','period':'yearly'},{'group':3,'platform':'ios','product_id':'Mate_D_Yearly','price':'175000','period':'yearly'}]",
    "unlimit_loading_seconds": 15,
}

await remoteConfig.fetchAndActivate()
