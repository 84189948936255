<template>
  <div class="sheet-container">
    <div class="sheet-title sans-serif">
      <strong>Table of Contents</strong>
      <img :src="require('@/tarot_box/pages/box_result_v2/assets/xbutton.svg')" alt="close" @click="$emit('close')"/>
    </div>
    <div class="sheet-content">
      <div v-for="(item, index) in filteredPageData" :key="index" class="index-item" @click="goPage(item.page_index)">
        <div class="item-texts">
          <span class="gray6 font12 texts"><strong>{{ item.chapter || `Chapter ${index + 1}` }}</strong></span>
          <span style="display: flex; flex-direction: row; align-items: center;">
            {{ item.page_title }}
            <img :src="require('@/tarot_box/pages/box_result_v2/assets/bottom-sheet/index_new_badge.svg')"
                  alt="new-badge"
                  style="margin-left: 6px;"
                 v-if="tarotBoxHistory.extra_question_status === 'new' && item.chapter === '부록'" />

          </span>
        </div>
        <div class="item-index" >
          <div class="gray5 font14">{{item.page_index}}</div>
          <img :src="require('../../assets/bottom-sheet/arrow-r.svg')" alt="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexComponent',
  props: {
    payload: {
      type: Object,
    },
    resultPageData: {
      type: Object,
    },
    tarotBoxHistory: {
      type: Object,
    }
  },
  computed: {
    filteredPageData() {
      return this.resultPageData
          .filter(item => item.page_type === 'title_page')
          .map(item => {
            if (item.page_title === '부록') {
              return {
                ...item,
                chapter: 'Special for You',
                page_title: 'Get Personalized Answers'
              };
            }
            return item;
          });
    },
  },
  methods: {
    goPage(index) {
      this.$emit("go-page", index);
    }
  }
}
</script>

<style scoped>
.sheet-container{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  padding: 12px 24px;
  margin-bottom: 76px;
  box-sizing: border-box;
  position: relative;
}

.sheet-title{
  font-family: "SUIT Variable", sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 30px 20px 10px 20px;
  box-sizing: border-box;
  background: white;
  z-index: 9999;
}

.sheet-content{
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;

  margin-top: 60px;
}

.index-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  border-top: 1px solid var(--Grayscale-Gray10);
  position: relative;
}

.item-texts{
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
  width: calc(100% - 30px);
}

.item-index{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.texts{
  font-family: "SUIT Variable", sans-serif;
  margin-right: 20px;
}
</style>