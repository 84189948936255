<template>
  <div class="popup-back-page">
    <form class="checkout-form" @submit.prevent="handleSubmit">
      <div id="close-button" class="title5" @click="close">CLOSE</div>
      <div class="title1">Payment</div>
      <img id="card-image" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/mastro%2Fcard_image.png?alt=media&token=f2dfa072-83ca-4295-9b09-54f8f77dd9c7"  alt=""/>
      <StripeElements
        v-if="elementsOptions.clientSecret"
        v-slot="{ elements, instance }"
        ref="elms"
        class="element-placeholder"
        :stripe-key="stripeKey"
        :instance-options="instanceOptions"
        :elements-options="elementsOptions"
        style="margin-left: 5px; margin-right: 5px;"
      >
        <StripeElement
          type="payment"
          ref="payment"
          :elements="elements"
          :options="paymentOptions"
        />
      </StripeElements>
      <StripeFrameSkeleton v-else class="element-placeholder" />
      <button
        id="checkout-button"
        type="submit"
        :disabled="!elementsOptions.clientSecret || isProgress"
      >
        {{ isProgress ? 'Progressing' : 'Pay now' }}
      </button>
    </form>
  </div>
</template>

<script>
import { StripeElements, StripeElement } from 'vue-stripe-js'
import {makeBoxResultForMastro, startMastroPayment} from "@/tarot_box/helper/mytarot_api";
import StripeFrameSkeleton from "@/tarot_box/pages/web_checkout/components/StripeFrameSkeleton.vue";

export default {
  name: "StripeCheckoutPopup",
  components: {
    StripeFrameSkeleton,
    StripeElements,
    StripeElement,
  },
  emits: ['close', 'payment-success', 'payment-fail', 'toast', 'update:transactionId'],
  props: {
    userBoxId: {
      type: Number,
      required: true,
    },
    userEmail: {
      type: String,
      required: true,
    },
    userInputData: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      stripeLoaded: false,
      // stripeKey: 'pk_test_51PaE1F2Mt2XIsSxrCfFz7Q8O5iLu0NNcumUY5hOpyM8i0CXZAX6J3RmUOCWVLvHyjBmmn26vy9n7pASJkoGdf8T900QhBUquDX',
      stripeKey: 'pk_live_51PaE1F2Mt2XIsSxrQj2cX8LeD1Fy7q7Z3UxGpEf1w0pdMD6bjEdM8crlxX8is32i2alkvPAaEqEqRznuGQ45MJvz00y8DlahXR',
      transactionId: null,
      instanceOptions: {},
      elementsOptions: {
        clientSecret: null,
        appearance: {
          theme: 'flat',
        },
      },
      paymentOptions: {
        business: {
          name: 'Mastro',
        },
        paymentMethodOrder: ['apple_pay', 'card'],
      },
      elements: null,
      isProgress: false,
      paymentInfo: {
        userBoxHistoryId: null,
        transactionId: null,
      },
    }
  },
  async mounted() {
    await this.makeNewBoxHistory();
    await this.startPayment();
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async handleSubmit() {
      if (this.isProgress) return;
      this.isProgress = true;

      try {
        const stripe = this.$refs.elms.instance;
        const elements = this.$refs.elms.elements;
        const {error: submitError} = await elements.submit();
        if (submitError) {
          console.error(submitError);
          return;
        }

        const paymentResult = await stripe.confirmPayment({
          elements,
          clientSecret: this.elementsOptions.clientSecret,
          confirmParams: {},
          redirect: 'if_required',
        });

        console.info('paymentResult', paymentResult);

        if (paymentResult.error) {
          console.error(paymentResult.error);
          this.$emit('payment-fail');
        } else if (paymentResult.paymentIntent.status === 'succeeded') {
          this.$emit('payment-success');
        } else {
          console.error(paymentResult.error);
          this.$emit('payment-fail');
        }
      } finally {
        this.isProgress = false;
      }
    },
    async makeNewBoxHistory() {
      console.info('makeNewBoxHistory', this.userBoxId, this.userEmail, this.userInputData);
      const boxRes = await makeBoxResultForMastro(this.userBoxId, this.userEmail, this.userInputData);
      if (boxRes.result === 'success') {
        console.info('makeBoxResult:', boxRes);
        this.paymentInfo.userBoxHistoryId = boxRes.data.id;
      } else {
        console.error('makeBoxResult:', boxRes.error);
        this.$emit('toast', 'Error occurred, Please try again.');
        throw new Error('Failed to make box history');
      }
    },
    async startPayment() {
      const res = await startMastroPayment(this.userEmail, this.paymentInfo.userBoxHistoryId);
      if (res.result !== 'success') {
        console.error(res.error);
        return;
      }
      const {
        transaction_id: transactionId,
        client_secret: clientSecret,
      } = await res.data;

      this.paymentInfo.transactionId = transactionId;
      this.elementsOptions.clientSecret = clientSecret;
      this.$emit('update:transactionId', transactionId);
    }
  },
}
</script>

<style scoped>
.popup-back-page {
  position: fixed; top: 0; left: 0; right: 0; bottom: 0; z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.80);
}

.checkout-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  width: 90vw;
  max-width: 432px;
  height: 550px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 16px 19px 18px;
  box-sizing: border-box;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease-in-out; /* 부드러운 트랜지션 효과 추가 */
}

.element-placeholder {
  flex: 1;
}

#close-button {
  align-self: flex-end;
  color: var(--Grayscale-Gray5);
}

#card-image {
  width: 225px;
  height: auto;
  margin-top: 12px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

#checkout-button {
  width: 100%;
  max-width: 302px;
  padding: 10px 0;
  box-sizing: border-box;
  background: var(--Grayscale-Gray3);
  display: flex;
  border-radius: 8px;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #FFFFFF;
  margin-top: 37px;
  justify-content: center;
}

#checkout-button:disabled {
  background: var(--Grayscale-Gray7);
}
</style>
