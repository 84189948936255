<template>
  <div class="confirm-popup-back-page">
    <div class="confirm-popup">
      <div class="confirm-popup-contents">
        <div class="confirm-popup-contents-title">
          결과를 확인해 볼까요?
        </div>
        <div class="confirm-popup-contents-delete">
          성향과 상황에 맞는<br>맞춤형 해석이 준비되었어요<br>고민에 도움이 될 결과를 확인해보세요
        </div>
      </div>

      <ShimmerImage
          src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/dev%2Fpayment_popup_icon.png?alt=media&token=a05eec50-53eb-4cd4-92f4-62d642139ec9&_gl=1*100hlkx*_ga*MTc3MDIwMzQ1Ni4xNjg2MTg0MDU2*_ga_CW55HF8NVT*MTY5ODIzOTYzOC4zOTAuMS4xNjk4MjM5NjkyLjYuMC4w"
          :imageStyle="{ width: '81vw' }"
          :heightRatio="0.55"
          :isCircle="false"
          :borderRadius="'10px'"/>

      <div class="confirm-popup-button-area">
        <div class="confirm-popup-button" v-wave @click="closeDonePopup()">
          다음에 보기
        </div>
        <div class="confirm-popup-button" v-wave @click="handleButtonFunction()">
          {{ boxPrice }}
          <svg class="clover-icon"
              xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.9562 9.9995C9.97081 9.98345 9.98533 9.96732 9.99976 9.95111C10.0142 9.96732 10.0287 9.98345 10.0433 9.9995C10.0287 10.0155 10.0142 10.0317 9.99976 10.0479C9.98533 10.0317 9.9708 10.0155 9.9562 9.9995ZM9.99976 18.0761C8.94734 19.2581 7.43637 19.999 5.75758 19.999C2.57775 19.999 0 17.3409 0 14.062C0 12.4904 0.592161 11.0615 1.55895 9.9995C0.592161 8.93748 0 7.50855 0 5.937C0 2.65808 2.57775 0 5.75758 0C7.43637 0 8.94734 0.740894 9.99976 1.92289C11.0522 0.740894 12.5632 0 14.242 0C17.4218 0 19.9995 2.65808 19.9995 5.937C19.9995 7.50855 19.4074 8.93748 18.4406 9.9995C19.4074 11.0615 19.9995 12.4904 19.9995 14.062C19.9995 17.3409 17.4218 19.999 14.242 19.999C12.5632 19.999 11.0522 19.2581 9.99976 18.0761Z" fill="#4BD1A9"/>
            <path d="M10.464 14.652C9.84 14.652 9.256 14.544 8.712 14.328C8.168 14.104 7.692 13.796 7.284 13.404C6.884 13.012 6.568 12.556 6.336 12.036C6.112 11.508 6 10.94 6 10.332C6 9.716 6.112 9.148 6.336 8.628C6.568 8.1 6.888 7.64 7.296 7.248C7.704 6.856 8.176 6.552 8.712 6.336C9.256 6.112 9.84 6 10.464 6C10.928 6 11.376 6.064 11.808 6.192C12.24 6.32 12.64 6.5 13.008 6.732C13.384 6.964 13.704 7.244 13.968 7.572L12.792 8.736C12.488 8.36 12.136 8.076 11.736 7.884C11.344 7.692 10.92 7.596 10.464 7.596C10.088 7.596 9.736 7.668 9.408 7.812C9.08 7.948 8.796 8.14 8.556 8.388C8.316 8.628 8.128 8.916 7.992 9.252C7.856 9.58 7.788 9.94 7.788 10.332C7.788 10.716 7.856 11.076 7.992 11.412C8.128 11.74 8.32 12.028 8.568 12.276C8.816 12.516 9.104 12.704 9.432 12.84C9.768 12.976 10.132 13.044 10.524 13.044C10.964 13.044 11.376 12.952 11.76 12.768C12.144 12.584 12.484 12.32 12.78 11.976L13.92 13.104C13.656 13.424 13.34 13.7 12.972 13.932C12.604 14.164 12.204 14.344 11.772 14.472C11.348 14.592 10.912 14.652 10.464 14.652Z" fill="black"/>
          </svg>
          결제
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getTarotUserInfo, purchaseBox} from "@/tarot_box/helper/mytarot_api";
import {mapGetters} from "vuex";
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
import {loggingEvent} from "@/common/app_webview_handler";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  components: {ShimmerImage},
  mounted() {
    loggingEvent('mytarot_purchase_popup');
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes']),
  },
  emits: ['paymentDone', 'update-user-info'],
  props: {
    boxPrice: {
      type: Number,
      required: true,
    },
    tarotBoxHistoryId: {
      type: Number,
      required: true,
    },
    closePopupCallback: {
      type: Function,
    },
    item:{
      type: Object,
    },
  },
  data() {
    return {
      userInfo: {},
      isCloverPaywallActive: false,
    };
  },

  methods: {
    async handleButtonFunction() {
      const apiResult = await getTarotUserInfo(this.user.userId, this.user.phoneNumber);
      this.userInfo = apiResult.data;

      if (this.userInfo.clover_balance < this.boxPrice) {
        logEvent('mytarot_purchase_paywall_go', {
          'tarot_box_id': this.item.tarot_box_id,
          'tarot_box_name': this.item.tarot_box_title
        });
        let url = new URL(window.location.href);
        const buildNumber = parseInt(url.searchParams.get('build_number'));
        if(buildNumber >= 84){
          this.isCloverPaywallActive = true;
        } else {
          let dataToSend = {action: 'showPayWall'};
          window.sendInfoToApp(JSON.stringify(dataToSend));
        }
      } else {
        await purchaseBox(this.user.userId, this.user.phoneNumber, this.tarotBoxHistoryId);
        this.$emit('paymentDone');
        logEvent('mytarot_purchase_complete', {
          'tarot_box_id': this.item.tarot_box_id,
          'tarot_box_name': this.item.tarot_box_title,
          'price': this.boxPrice
        });
      }
    },
    closeDonePopup() {
      loggingEvent('mytarot_purchase_popup_close');
      this.closePopupCallback();
    },
    closePaywall() {
      this.$emit('update-user-info')
      this.isCloverPaywallActive = false;
    },
  },
}
</script>

<style scoped>
.confirm-popup-back-page{
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.80);
}

.confirm-popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 40px;
  width: 90%;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  box-sizing: border-box;

  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
}

.confirm-popup-contents{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 12px;
}

.confirm-popup-contents-title{
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.confirm-popup-contents-delete {
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.confirm-popup-button-area {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.confirm-popup-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  background: #1E2352;
  color: #FFF;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.confirm-popup-button:first-child {
  background: #E9E9E9;
  color: #6E6E6E;
}

.confirm-popup-button:last-child {
  background: #1E2352;
  color: #FFF;
}

.clover-icon {
  margin: 0 6px 0 4px;
}
</style>
