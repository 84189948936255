<template>
  <div class="component-container" :style="{ paddingTop: padding }">
    <img id="symbol" :src="tarotBoxHistory.output_page_meta_data.objet_icon_image_url" alt="symbol"/>
    <div class="heading-texts">
      <span class="font14 gray5"><strong>Chapter {{ currentChapterIndex }}</strong></span>
      <span class="serif gray1 font20">{{ currentPageData.page_title }}</span>
    </div>
    <div class="profile-table">
      <div class="name-table font14">
        {{ currentPageData.page_content.input.name }}
      </div>
      <div class="info-table">
        <div>{{ currentPageData.page_content.input.birth_date }}</div>
        <img :src="require('../../assets/detail-page/table_crossline.svg')" alt="|"/>
        <div>{{ currentPageData.page_content.input.birth_time }}</div>
        <img :src="require('../../assets/detail-page/table_crossline.svg')" alt="|"/>
        <div>{{ currentPageData.page_content.input.birth_place }}</div>
      </div>
    </div>
    <div class="planetary-table">
      <!-- 헤더 행 -->
      <div class="table-row header">
        <div class="sign-column">
          <div class="text3 gray3" style="margin-left: 10px;">SIGNS</div>
        </div>
        <div class="name-column">
          <div class="text3 gray3" style="margin-left: 10px;">PLANETS</div>
        </div>
        <div class="house-column">
          <div class="text3 gray3">HOUSES</div>
        </div>
      </div>
      <!-- 데이터 행 -->
      <div v-for="(row, index) in processedChartData" :key="index" class="table-row">
        <div class="sign-column" :class="{ 'hide-content': !row.showSignContent }">
          <div class="text1 gray3" style="margin-left: 10px;">{{ row.sign }}</div>
        </div>
        <div class="name-column">
          <div class="name-item">
            <NatalChartSymbols :sign="row.name" :color="'#FFFFFF'"/>
            <div class="text1 gray3">{{ row.name.toUpperCase() }}</div>
          </div>
        </div>
        <div class="house-column" :class="{ 'hide-content': !row.showHouseContent }">
          <div class="title4 gray3">{{ row.house }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logEvent } from "@/tarot_box/helper/native_api";
import NatalChartSymbols from "@/tarot_box/pages/box_input_v2/assets/NatalChartSymbols.vue";

export default {
  name: "PlanetaryInfo",
  components: {
    NatalChartSymbols
  },
  props: {
    currentIndex: {
      type: Number,
    },
    currentPageData: {
      type: Object,
    },
    backgroundColor: {
      type: String,
    },
    tarotBoxHistory: {
      type: Object,
    },
    currentChapterIndex: {
      type: Number,
    },
    isShare: {
      type: Boolean,
    },
  },
  mounted() {
    this.$nextTick(() => {
      logEvent('result_planetary_into', {})
    });
  },
  computed: {
    padding() {
      return this.isShare ? '48px' : '100px';
    },
    processedChartData() {
      const charts = this.currentPageData.page_content.report.charts;
      if (!charts || charts.length === 0) return [];

      // house와 sign으로 정렬
      const sortedData = [...charts].sort((a, b) => {
        if (a.house !== b.house) return a.house - b.house;
        if (a.sign !== b.sign) return a.sign.localeCompare(b.sign);
        return a.name.localeCompare(b.name);
      });

      let result = [];
      let lastSign = null;
      let lastHouse = null;
      let houseGroup = [];

      sortedData.forEach((item, index, array) => {
        const showSignContent = item.sign !== lastSign;

        if (item.house !== lastHouse) {
          // 새로운 하우스 그룹 시작
          if (houseGroup.length > 0) {
            // 이전 그룹의 마지막 항목에 하우스 표시
            houseGroup[houseGroup.length - 1].showHouseContent = true;
            result.push(...houseGroup);
          }
          houseGroup = [];
        }

        houseGroup.push({
          sign: item.sign,
          name: item.name,
          house: item.house,
          showSignContent,
          showHouseContent: false // 기본적으로 false로 설정
        });

        lastSign = item.sign;
        lastHouse = item.house;

        // 마지막 항목 처리
        if (index === array.length - 1) {
          houseGroup[houseGroup.length - 1].showHouseContent = true;
          result.push(...houseGroup);
        }
      });

      return result;
    },
  },
}
</script>

<style scoped>
.component-container {
  width: 100vw;
  max-width: 480px;
  min-height: 100vh;
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
  padding: 0 24px;
}

.heading-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 6px;
}

#symbol {
  height: 30px;
}

.profile-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-top: 28px;
}

.name-table {
  width: 100%;
  background: var(--Grayscale-Gray3);
  border-radius: 4px;
  font-weight: bold;
  padding: 4px 0;
  box-sizing: border-box;
  color: var(--Grayscale-Gray12);
}

.info-table {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: var(--Grayscale-Gray10);
  border-radius: 4px;
  padding: 10px 30px;
  box-sizing: border-box;
  color: var(--Grayscale-Gray2);
}

.planetary-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 0 20px 0;
  box-sizing: border-box;
  min-height: 300px;
  margin-bottom: 100px;
}

.table-row {
  display: flex;
  padding: 0;
}

.table-row:last-child {
  border-bottom: none
}

.house-column {
  display: flex;
  align-items: center;
  border-right: 1px solid var(--Grayscale-Gray12);
  border-bottom: 1px solid var(--Grayscale-Gray12);
}

.sign-column{
  display: flex;
  align-items: center;
  border-right: 1px solid var(--Grayscale-Gray12);
  border-top: 1px solid var(--Grayscale-Gray12);
}

.table-row:nth-child(2) .sign-column {
  border-top: none;
}

.table-row:last-child .house-column{
  border-bottom: none;
}

.name-column{
  display: flex;
  align-items: center;
  border-right: 1px solid var(--Grayscale-Gray12);
  border-bottom: none;
}

.house-column {
  border-right: none;
  justify-content: center;
}

.table-row.header {
  font-weight: 700;
}

.table-row.header .sign-column,
.table-row.header .name-column,
.table-row.header .house-column {
  border-bottom: 1px solid var(--Grayscale-Gray12);
  border-top: 1px solid var(--Grayscale-Gray12);
  padding-top: 10px;
  padding-bottom: 10px;
}

.table-row:not(.header):first-of-type .sign-column,
.table-row:not(.header):first-of-type .name-column,
.table-row:not(.header):first-of-type .house-column {
  border-top: none;
}

.hide-content .text1, .hide-content .title4 {
  visibility: hidden;
}

.hide-content {
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.table-row:not(.header) .sign-column.hide-content{
  border-right: 1px solid var(--Grayscale-Gray12);
  border-bottom: none;
  border-top: none;
}
.table-row:not(.header) .house-column.hide-content {
  border:none;
}


.name-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
  color: var(--Grayscale-Gray5);
  padding: 16px 0 16px 10px;
}

.text1, .title4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text1 {
  font-size: 14px;
  font-weight: 500;
}

.title4 {
  font-size: 16px;
  font-weight: 600;
}

.name-item :deep(svg) {
  width: 20px;
  height: 20px;
}

@media (min-width: 200px) {
  .sign-column {
    flex: 3;
  }

  .name-column {
    flex: 5;
  }

  .house-column {
    flex: 1.6;
  }

  .proceed-button-wrapper {
    padding: 10px 24px;
  }

  .answer-title {
    font-size: 16px;
    padding: 6px 12px;
  }
}
</style>
