<template>
  <div
      class="component-container" :style="{ paddingTop: padding}">
    <img id="symbol" :src="tarotBoxHistory.output_page_meta_data.objet_icon_image_url" alt="symbol"/>
    <div class="heading-texts">
      <span class="font14 gray5"><strong>Chapter {{ currentChapterIndex }}</strong></span>
      <span class="serif gray1 font20">{{ currentPageData.page_title }}</span>
    </div>
    <div class="profile-table">
      <div class="name-table font14">
        {{ currentPageData.page_content.partner_name ? currentPageData.page_content.partner_name : userName }}님
      </div>
      <div class="info-table">
        <div>{{currentPageData.page_content.birth_date}}</div>
        <img :src="require('../../assets/detail-page/table_crossline.svg')" alt="|"/>
        <div>{{ currentPageData.page_content.saju_hour }}({{ currentPageData.page_content.saju_hour_hanja }})</div>
        <img :src="require('../../assets/detail-page/table_crossline.svg')" alt="|"/>
        <div>{{ currentPageData.page_content.gender }}</div>
      </div>
    </div>
    <div class="saju-table">
      <div class="table-headings">
        <div class="font12 white"><strong>시주</strong></div>
        <div class="font12 white"><strong>일주</strong></div>
        <div class="font12 white"><strong>월주</strong></div>
        <div class="font12 white"><strong>년주</strong></div>
      </div>
      <div class="period-column">

        <!-- 시주 -->
        <div class="saju-sipsung">
          <div class="sipsin"
               @click="openSipsungInfo(currentPageData.page_content.sipsung_of_hour_heavenly_stem_desc)">
            {{currentPageData.page_content.sipsung_of_hour_heavenly_stem_desc.sipsung}}
          </div>
          <div class="heavenly-earthy"
               :class="getColorClass(currentPageData.page_content.saju_hour_heavenly_5hang)"
               :style="getColorStyle(currentPageData.page_content.saju_hour_heavenly_5hang)">
            <div class="hangeul">{{currentPageData.page_content.saju_hour_heavenly_hangeul}}</div>
            <div class="hanja">{{currentPageData.page_content.saju_hour_heavenly_hanja}}</div>
            <div class="hang">{{currentPageData.page_content.saju_hour_heavenly_5hang}}</div>
          </div>
          <div class="heavenly-earthy"
               :class="getColorClass(currentPageData.page_content.saju_hour_earthly_5hang)"
               :style="getColorStyle(currentPageData.page_content.saju_hour_earthly_5hang)">
            <div class="hangeul">{{currentPageData.page_content.saju_hour_earthly_hangeul}}</div>
            <div class="hanja">{{currentPageData.page_content.saju_hour_earthly_hanja}}</div>
            <div class="hang">{{currentPageData.page_content.saju_hour_earthly_5hang}}</div>
          </div>
          <div class="sipsin"
               @click="openSipsungInfo(currentPageData.page_content.sipsung_of_hour_earthly_branch_desc)">
            {{currentPageData.page_content.sipsung_of_hour_earthly_branch_desc.sipsung}}
          </div>
        </div>

        <!-- 일주 -->
        <div class="saju-sipsung">
          <div class="sipsin"
               @click="openSipsungInfo(currentPageData.page_content.sipsung_of_day_heavenly_stem_desc)">
            {{currentPageData.page_content.sipsung_of_day_heavenly_stem_desc.sipsung}}
          </div>
          <div class="heavenly-earthy"
               :class="getColorClass(currentPageData.page_content.saju_day_heavenly_5hang)"
               :style="getColorStyle(currentPageData.page_content.saju_day_heavenly_5hang)">
            <div class="hangeul">{{currentPageData.page_content.saju_day_heavenly_hangeul}}</div>
            <div class="hanja">{{currentPageData.page_content.saju_day_heavenly_hanja}}</div>
            <div class="hang">{{currentPageData.page_content.saju_day_heavenly_5hang}}</div>
          </div>
          <div class="heavenly-earthy"
               :class="getColorClass(currentPageData.page_content.saju_day_earthly_5hang)"
               :style="getColorStyle(currentPageData.page_content.saju_day_earthly_5hang)">
            <div class="hangeul">{{currentPageData.page_content.saju_day_earthly_hangeul}}</div>
            <div class="hanja">{{currentPageData.page_content.saju_day_earthly_hanja}}</div>
            <div class="hang">{{currentPageData.page_content.saju_day_earthly_5hang}}</div>
          </div>
          <div class="sipsin"  @click="openSipsungInfo(currentPageData.page_content.sipsung_of_day_earthly_branch_desc)">
            {{currentPageData.page_content.sipsung_of_day_earthly_branch_desc.sipsung}}
          </div>
        </div>

        <!-- 월주 -->
        <div class="saju-sipsung">
          <div class="sipsin"
               @click="openSipsungInfo(currentPageData.page_content.sipsung_of_month_heavenly_stem_desc)">
            {{currentPageData.page_content.sipsung_of_month_heavenly_stem_desc.sipsung}}
          </div>
          <div class="heavenly-earthy"
               :class="getColorClass(currentPageData.page_content.saju_month_heavenly_5hang)"
               :style="getColorStyle(currentPageData.page_content.saju_month_heavenly_5hang)">
            <div class="hangeul">{{currentPageData.page_content.saju_month_heavenly_hangeul}}</div>
            <div class="hanja">{{currentPageData.page_content.saju_month_heavenly_hanja}}</div>
            <div class="hang">{{currentPageData.page_content.saju_month_heavenly_5hang}}</div>
          </div>
          <div class="heavenly-earthy"
               :class="getColorClass(currentPageData.page_content.saju_month_earthly_5hang)"
               :style="getColorStyle(currentPageData.page_content.saju_month_earthly_5hang)">
            <div class="hangeul">{{currentPageData.page_content.saju_month_earthly_hangeul}}</div>
            <div class="hanja">{{currentPageData.page_content.saju_month_earthly_hanja}}</div>
            <div class="hang">{{currentPageData.page_content.saju_month_earthly_5hang}}</div>
          </div>
          <div class="sipsin"
               @click="openSipsungInfo(currentPageData.page_content.sipsung_of_month_earthly_branch_desc)">
            {{currentPageData.page_content.sipsung_of_month_earthly_branch_desc.sipsung}}
          </div>
        </div>

        <!-- 년주 -->
        <div class="saju-sipsung">
          <div class="sipsin"
               @click="openSipsungInfo(currentPageData.page_content.sipsung_of_year_heavenly_stem_desc)">
            {{currentPageData.page_content.sipsung_of_year_heavenly_stem_desc.sipsung}}
          </div>
          <div class="heavenly-earthy"
               :class="getColorClass(currentPageData.page_content.saju_year_heavenly_5hang)"
               :style="getColorStyle(currentPageData.page_content.saju_year_heavenly_5hang)">
            <div class="hangeul">{{currentPageData.page_content.saju_year_heavenly_hangeul}}</div>
            <div class="hanja">{{currentPageData.page_content.saju_year_heavenly_hanja}}</div>
            <div class="hang">{{currentPageData.page_content.saju_year_heavenly_5hang}}</div>
          </div>
          <div class="heavenly-earthy"
               :class="getColorClass(currentPageData.page_content.saju_year_earthly_5hang)"
               :style="getColorStyle(currentPageData.page_content.saju_year_earthly_5hang)">
            <div class="hangeul">{{currentPageData.page_content.saju_year_earthly_hangeul}}</div>
            <div class="hanja">{{currentPageData.page_content.saju_year_earthly_hanja}}</div>
            <div class="hang">{{currentPageData.page_content.saju_year_earthly_5hang}}</div>
          </div>
          <div class="sipsin"
               @click="openSipsungInfo(currentPageData.page_content.sipsung_of_year_earthly_branch_desc)">
            {{currentPageData.page_content.sipsung_of_year_earthly_branch_desc.sipsung}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "SajuSipsung",
  components: {},
  props: {
    currentIndex: {
      type: Number,
    },
    currentPageData: {
      type: Object,
    },
    backgroundColor: {
      type: String,
    },
    tarotBoxHistory: {
      type: Object,
    },
    currentChapterIndex: {
      type: Number,
    },
    userName: {
      type: String,
    },
    isShare: {
      type: Boolean,
    },
  },
  mounted() {
    this.$nextTick(() => {
      logEvent('result_saju8_into', {})
    });
  },
  computed: {
    padding() {
      return this.isShare ? '48px' : '100px';
    }
  },
  methods: {
    getColorClass(hang) {
      switch (hang) {
        case '수':
          return 'hang-su';
        case '토':
          return 'hang-to';
        case '화':
          return 'hang-hwa';
        case '금':
          return 'hang-geum';
        case '목':
          return 'hang-mok';
        default:
          return '';
      }
    },
    getColorStyle(hang) {
      switch (hang) {
        case '수':
          return { backgroundColor: '#525252', borderColor: '#343030', color: 'white' };
        case '토':
          return { backgroundColor: '#F4D156', borderColor: '#B19941', color: 'black' };
        case '화':
          return { backgroundColor: '#CF5354', borderColor: '#90292A', color: 'white' };
        case '금':
          return { backgroundColor: '#CFCFCF', borderColor: '#908F89', color: 'black' };
        case '목':
          return { backgroundColor: '#4768A8', borderColor: '#28457C', color: 'white' };
        default:
          return {};
      }
    },
    openSipsungInfo(sipsungInfo) {
      logEvent('result_saju8_10sung_click',{'10sung': sipsungInfo.sipsung})
      this.$emit('open-sipsung-info', sipsungInfo);
    }
  }
}
</script>

<style scoped>
.saju-table{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  gap: 8px;
  box-sizing: border-box;
}

.period-column{
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.saju-sipsung{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.sipsin{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  background: var(--Grayscale-Gray12);
  border-radius: 50px;
  border: 1px solid var(--Grayscale-Gray6);
  font-size: 14px;
  font-weight: bold;
}

.heavenly-earthy{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--Grayscale-Gray9);
  border-radius: 12px;
  width: 100%;
  padding: 10px 0;
  box-sizing: border-box;
}

.hangeul{
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
}

.hang{
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
}

.hanja{
  font-size: 24px;
  margin: 4px 0;
  line-height: 1;
}


.saju-border{
  width: 100%;
  height: 7.67vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  img{
    width: 100%;
  }
}

.saju-table{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 6px;
  margin-top: 16px;
}

.table-headings{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  background: var(--Grayscale-Gray3);
  border-radius: 4px;
  font-weight: bold;
  padding: 6px 27px;
  box-sizing: border-box;
}

.profile-table{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 6px;
  margin-top: 28px;
}

.name-table{
  width: 100%;
  background: var(--Grayscale-Gray3);
  border-radius: 4px;
  font-weight: bold;
  padding: 4px 0;
  box-sizing: border-box;
  color: var(--Grayscale-Gray12);
}

.info-table{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: var(--Grayscale-Gray10);
  border-radius: 4px;
  padding: 10px 30px;
  box-sizing: border-box;
  color: var(--Grayscale-Gray2);

}

.component-container {
  width: 100vw;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding: 100px 24px 83px 24px;
  box-sizing: border-box;
  gap: 8px;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

.heading-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 6px;
}

#symbol {
  height: 30px;
}

.white{
  color: white;
}

</style>