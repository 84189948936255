<template>
  <div class="modal" :class="{ 'is-active': isActive }" v-if="isActive">
    <div class="overlay" @click="backgroundClick"></div>
    <div class="popup-container" ref="popupContainer">
      <div class="x-mark" @click="backgroundClick">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="black"/>
        </svg>
      </div>
      <div v-wave class="selections">
        <span class="title4"  @click="openDeletePopup">
          삭제하기
        </span>

      </div>
      <div class="popup_footer"></div>
    </div>
  </div>

</template>

<script>
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "ExtraPopup",
  props: {
    isActive: {
      type: Boolean,
    },
    closePopupCallback: {
      type: Function,
    },
    openDeleteConfirmPopupCallback: {
      type: Function,
    },
  },
  methods: {
    backgroundClick() {
      this.closePopupCallback();
    },
    openDeletePopup() {
      logEvent('mytarot_option', {});
      this.openDeleteConfirmPopupCallback();
      this.backgroundClick();
    }
  }
}
</script>

<style>
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.popup-container {
  position: fixed;
  bottom: 0; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, 0); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: 100vw;
  padding: 52px 0 30px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000 !important;
  border-radius: 8px 8px 0 0;
}

.selections{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 24px;
}

.x-mark{
  position: fixed;
  top: 18px;
  right: 18px;
}

.title3{
  text-align: left;
}
</style>