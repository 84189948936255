<template>
  <div class="confirm-popup-back-page">
    <div class="confirm-popup">
      <div class="confirm-popup-contents">
        <div class="confirm-popup-contents-title">
          결제 완료!<br>
          타로 해석을 시작합니다
        </div>
        <div class="confirm-popup-contents-delete">
          맞춤형 타로 해석을 진행하고 있습니다<br>
          5분~최대 30분의 시간이 소요됩니다<br>
          결과가 <span>완성되면 알림과 메시지</span>를 보내드릴게요<br>
          결과는 <span>나의 타로</span>에서도 확인하실 수 있습니다<br>
        </div>
      </div>

      <ShimmerImage
          :height-ratio=364/425
          :image-style="{width: '45vw'}"
          :src="require('@/tarot_box/pages/my_tarot/assets/images/payment_done_popup_image.png')"/>

      <div class="confirm-popup-button-area">
        <div class="confirm-popup-button" v-wave @click="closeDonePopup">
          닫기
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {loggingEvent} from "@/common/app_webview_handler";
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";

export default {
  name: 'PaymentDonePopup',
  components: {
    ShimmerImage,
  },
  props: {
    closePopupCallback: {
      type: Function,
    },
  },
  mounted() {
    loggingEvent('snack_purchase_done_popup');
  },
  methods: {
    closeDonePopup() {
      this.closePopupCallback();
    },
  },
  emits: ['paymentDone'],
}
</script>

<style scoped>
.confirm-popup-back-page{
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(0, 0, 0, 0.80);
}

.confirm-popup{
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 25px;
  width: 90%;
  height: fit-content;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  box-sizing: border-box;

  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.15);
}

.confirm-popup-contents{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  gap: 12px;
}

.confirm-popup-contents-title{
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.confirm-popup-contents-delete {
  text-align: center;
  color: #000;
  font-size: 14px;
  font-weight: 400;
}

.confirm-popup-button-area {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 9px;
}

.confirm-popup-button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  background: #1E2352;
  color: #FFF;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 700;
}

.icon-area {
  max-width: 70%;
  max-height: calc(var(--vh, 1vh) * 100 * 0.6);
}

span {
  color: #3140B4;
}
</style>