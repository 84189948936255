<template>
  <div class="date-input-container">
    <div class="alert" v-if="errorMessage">{{ errorMessage }}</div>
    <div class="date-input-set">
      <input
          v-model="monthInput"
          type="tel"
          placeholder="MM"
          class="date-input"
          :class="{ 'invalid': !isValidMonth && !isMonthFocused && monthInput !== '', 'focused': isMonthFocused }"
          maxlength="2"
          @focus="onFocus('Month')"
          @blur="onBlur('Month')"
          @input="onInput('month', $event)"
          ref="monthInput">
      <span class="title3">-</span>
    </div>
    <div class="date-input-set">
      <input
          v-model="dayInput"
          type="tel"
          placeholder="DD"
          class="date-input"
          :class="{ 'invalid': !isValidDay && !isDayFocused && dayInput !== '', 'focused': isDayFocused }"
          maxlength="2"
          @focus="onFocus('Day')"
          @blur="onBlur('Day')"
          @input="onInput('day', $event)"
          ref="dayInput">
      <span class="title3">-</span>
    </div>
    <div class="date-input-set">
      <input
          v-model="yearInput"
          type="tel"
          placeholder="YYYY"
          class="date-input"
          :class="{ 'invalid': !isValidYear && !isYearFocused && yearInput !== '', 'focused': isYearFocused }"
          maxlength="4"
          @focus="onFocus('Year')"
          @blur="onBlur('Year')"
          @input="onInput('year', $event)"
          ref="yearInput">
    </div>
  </div>
</template>

<script>
export default {
  name: "DateInput",
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    inputItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      monthInput: '',
      dayInput: '',
      yearInput: '',
      internalDate: '00-00-0000',
      isValidMonth: true,
      isValidDay: true,
      isValidYear: true,
      isMonthFocused: false,
      isDayFocused: false,
      isYearFocused: false,
      errorMessage: '',
      isCompleted: false
    }
  },
  emits: ['update:modelValue', 'focus', 'submitAnswer', 'warn', 'invalidDate', 'invalid-input'],
  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue && newValue.match(/^\d{2}-\d{2}-\d{4}$/)) {
          const [month, day, year] = newValue.split('-');
          this.monthInput = month;
          this.dayInput = day;
          this.yearInput = year;
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.modelValue && this.modelValue.match(/^\d{2}-\d{2}-\d{4}$/)) {
        const [month, day, year] = this.modelValue.split('-');
        this.monthInput = month;
        this.dayInput = day;
        this.yearInput = year;
      }
      this.$refs.monthInput.focus();
    });
  },
  methods: {
    onFocus(field) {
      this[`is${field}Focused`] = true;
      this.$emit('focus');
    },
    onInput(field, event) {
      let value = this.filterNonNumeric(event.target.value);

      if (field === 'month') {
        if (value.length === 1 && parseInt(value) > 1) {
          value = '0' + value;
          this.$refs.dayInput.focus();
        } else if (value.length === 2) {
          this.$refs.dayInput.focus();
        }
      } else if (field === 'day') {
        if (value.length === 1 && parseInt(value) >= 4) {
          value = '0' + value;
          this.$refs.yearInput.focus();
        } else if (value.length === 2) {
          this.$refs.yearInput.focus();
        }
      } else if (field === 'year' && value.length === 4) {
        this.$refs.yearInput.blur();
      }

      this[`${field}Input`] = value;
      this.validateAndHandle();
    },
    onBlur(field) {
      this[`is${field}Focused`] = false;

      if (field === 'Month' || field === 'Day') {
        if (this[`${field.toLowerCase()}Input`].length === 1) {
          this[`${field.toLowerCase()}Input`] = this[`${field.toLowerCase()}Input`].padStart(2, '0');
        }
      }

      this.validateAndHandle();
    },
    validateAndHandle() {
      this.errorMessage = '';

      const month = this.monthInput;
      const day = this.dayInput;
      const year = this.yearInput;

      if(this.yearInput.length < 4){
        this.$emit('invalid-input')
      }

      // Reset validation state if all fields are empty
      if (!month && !day && !year) {
        this.resetValidationState();
        return;
      }

      this.isValidMonth = this.validateMonth(month);
      this.isValidDay = this.validateDay(month, day);
      this.isValidYear = this.validateYear(year);

      const isMonthDayEntered = month.length === 2 && day.length === 2;
      const isFullyEntered = isMonthDayEntered && year.length === 4;

      if (isFullyEntered) {
        if (this.isValidMonth && this.isValidDay && this.isValidYear) {
          this.submitValidDate(month, day, year);
        } else {
          this.errorMessage = 'Please enter a valid date';
          this.$emit('invalidDate');
        }
      } else if (isMonthDayEntered) {
        if (!this.isValidMonth || !this.isValidDay) {
          this.errorMessage = 'Please enter a valid month and day';
        }
      } else if (month.length === 2 && !this.isValidMonth) {
        this.errorMessage = 'Please enter a valid month';
      } else if (day.length === 2 && !this.isValidDay) {
        this.errorMessage = 'Please enter a valid day';
      } else if (year.length === 4 && !this.isValidYear) {
        this.errorMessage = 'Please enter a valid year';
      }

      if (this.errorMessage) {
        this.$emit('invalid-input')
        this.$emit('warn', this.errorMessage);
      }
    },
    resetValidationState() {
      this.isValidMonth = true;
      this.isValidDay = true;
      this.isValidYear = true;
      this.errorMessage = '';
    },
    submitValidDate(month, day, year) {
      month = month.padStart(2, '0');
      day = day.padStart(2, '0');
      year = year.padStart(4, '0');

      this.internalDate = `${month}-${day}-${year}`;
      this.isCompleted = true;
      this.$emit('update:modelValue', this.internalDate);
      this.$emit('submitAnswer', {
        ...this.inputItem,
        value: this.internalDate,
        inputFormType: this.inputItem.inputFormType,
        inputFormSubtype: this.inputItem.inputFormSubtype
      });
    },
    validateMonth(month) {
      return month.length === 2 && month >= '01' && month <= '12';
    },
    validateDay(month, day) {
      if (day.length !== 2) return false;
      const daysInMonth = new Date(2000, parseInt(month), 0).getDate();
      return day >= '01' && day <= daysInMonth.toString().padStart(2, '0');
    },
    validateYear(year) {
      if (year.length !== 4) return false;
      const currentYear = new Date().getFullYear();
      return year >= '1900' && year <= currentYear.toString();
    },
    filterNonNumeric(value) {
      return value.replace(/[^0-9]/g, '');
    }
  }
}
</script>

<style scoped>
.date-input-container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}

.date-input-set {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.date-input {
  padding: 24px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  background: var(--Grayscale-Gray11, #F5F5F5);
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}





.date-input::placeholder {
  font-size: 18px;
  color: var(--Grayscale-Gray8, #8C8C8C);
}

.title3 {
  white-space: nowrap;
  margin-left: 4px; /* '년', '월', '일' 텍스트와 입력 필드 사이의 간격 */
}

.alert {
  position: absolute;
  top: -24px;
  right: 0;

  color: var(--Color-palette-System-color-Red);
  font-weight: bold;
  font-size: 12px;
}

.date-input.invalid {
  outline: 2px solid var(--Color-palette-System-color-Red);
}

.date-input:focus {
  outline: 2px solid var(--Color-palette-Primary-Default);
}

.date-input.focused {
  outline: 2px solid var(--Color-palette-Primary-Default);
}

</style>