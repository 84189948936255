<template>
  <div class="screen">
    <div class="header-bar">
      <img id="logo" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/mastro%2Fmastro_logo.png?alt=media&token=0de4f766-25e8-4f59-8411-47d56c02258d" alt="logo">
      <div class="back-button" @click="toHome">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 1.61143L14.3886 0L8 6.38857L1.61143 0L0 1.61143L6.38857 8L0 14.3886L1.61143 16L8 9.61143L14.3886 16L16 14.3886L9.61143 8L16 1.61143Z" fill="black"/>
        </svg>
      </div>
    </div>
    <div class="container">
      <h1>Your purchase is complete.</h1>
      <div class="description text1">Once the interpretation is complete,<br>we will notify you via email.</div>
      <div class="notification text1">The interpretation may take<br><div class="title4 highlight">between 5 and 30 minutes.</div></div>
      <img id="result-image" src="https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/mastro%2Fmastro_result_image.png?alt=media&token=0ab01bcc-c3e5-41bd-8fb6-aae92f859513" alt="result-image">
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {updatePurchaseReferrerForMastro} from "@/tarot_box/helper/mytarot_api";
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "WebCheckoutCompletePage",
  data() {
    return {
      bgColor: '#1E2352',
      boxData: null,
      transactionId: '',
      transactionLogging: false,
      purchaseWebReferrer: 'not set',
      isLogged: false,
    }
  },
  async mounted() {
    //이 컴포넌트에서 뒤로가기 로직 작동하지 않도록 제어
    history.pushState(null, null, location.href);
    window.addEventListener('popstate', this.handlePopState);
    // URL 디코딩
    const decodedUrl = decodeURIComponent(window.location.href);
    const urlParams = new URLSearchParams(new URL(decodedUrl).search);
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    this.transactionId = urlParams.get('transaction_id');
    this.$nextTick(() => {
      this.logFacebookPixel();
      setTimeout(() => {
        this.updateReferrer();
      }, 1500);
    });
  },
  beforeUnmount() {
    // 컴포넌트가 제거될 때 이벤트 리스너를 제거
    window.removeEventListener('popstate', this.handlePopState);
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes', 'utmReferrer']),
  },
  methods: {
    updateReferrer() {
     if(localStorage.getItem('webReferrer') !== null){
        this.purchaseWebReferrer = localStorage.getItem('webReferrer');
        console.log('local storage', this.purchaseWebReferrer);
      } else if(this.utmReferrer){
        this.purchaseWebReferrer = this.utmReferrer;
        console.log('store ref', this.purchaseWebReferrer);
      } else {
       this.purchaseWebReferrer = 'Not Set'
     }
      updatePurchaseReferrerForMastro(this.transactionId, this.purchaseWebReferrer);
    },
    logFacebookPixel(){
      const urlParams = new URLSearchParams(window.location.search);
      const boxPrice = urlParams.get('box_price');
      const boxId = urlParams.get('box_id');
      const boxTitle  = urlParams.get('box_title');
      if(this.isLogged === false){
        window.fbq('track', 'Purchase', {
          value: boxPrice,
          currency: 'USD',
          content_ids: [boxId],
          content_type: 'product',
          content_name: boxTitle,
          content_category: 'mastro',
        }, {eventID: this.transactionId});
        logEvent('purchase_completed_into', {
          'transaction_id': this.transactionId,
          'box_id': boxId,
          'box_title': boxTitle,
          'box_price': boxPrice,
          'web_referrer' : this.utmReferrer
        })
      }
      this.isLogged = true;
    },
    handlePopState() {
      history.pushState(null, null, location.href);
    },
    toHome() {
      this.$router.push('/?is_web=true');
    }
  },
}
</script>

<style scoped>
.screen {
  background: var(--Grayscale-Gray11);
  position: relative;
  width: 100vw;
  height: 100dvh;
  overflow-y: scroll;
  max-width: 480px;
  margin: 0 auto;
  box-sizing: border-box;
}

.header-bar {
  width: 100%;
  height: 44px;
  max-width: 480px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
  border-bottom: 1px solid var(--Grayscale-Gray10);
  flex-shrink: 0;
  z-index: 900;
}

.back-button {
  position: absolute;
  display: flex;
  z-index: 9999;
  left: 16px;
}

#logo {
  height: 18px;
  width: auto;
}

.container {
  position: relative;
  top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.description{
  margin-top: 23px;
}

h1{
  color: var(--Grayscale-Gray2);
  text-align: center;
  margin: 0;
  font-family: SUIT;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.notification {
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray4);
  margin-top: 12px;
}

.highlight {
  color: #2D3E73;
}

#result-image {
  margin-top: 56px;
  width: 250px;
  height: 209px;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
</style>
