import { createApp } from 'vue'
import { createRouter, createWebHistory } from "vue-router";
import * as Sentry from "@sentry/vue";

import App from './App.vue'
import store from "@/tarot_box/helper/store";
import utils from "@/tarot_box/helper/utils";
import Vue3TouchEvents from "vue3-touch-events";
import VWave from "v-wave";
import vueDebounce from 'vue-debounce';
import {LoadingPlugin} from 'vue-loading-overlay';
import {isNewAppVersion} from "@/utils/appVersion";
import AppListenerPlugin from '@/common/app_listener_plugin';
import mitt from "mitt";



import HomePage from "./pages/home/HomePage.vue";
import MyTarotPage from "@/tarot_box/pages/my_tarot/MyTarotPage.vue";
import HomePage2 from "@/tarot_box/pages/home/HomePage2.vue";


import WebCheckoutPage from "@/tarot_box/pages/web_checkout/pages/WebCheckoutPage.vue";
import WebCheckoutCompletePage from "@/tarot_box/pages/web_checkout/pages/WebCheckoutCompletePage.vue";

import OutputPage from "@/tarot_box/pages/box_result_v2/OutputPage.vue";
import InputPage from "@/tarot_box/pages/box_input_v2/InputPage.vue";


const routes = [
    { path: "/", redirect: "/tarot_box/home?is_web=true"},
    { path: "/tarot_box/home", component: HomePage },
    { path: "/tarot_box/home2", component: HomePage2},
    { path: "/tarot_box/mytarot", component: MyTarotPage },
    { path: "/tarot_box/input_v2/:boxId/:language?", component: InputPage},
    { path: "/tarot_box/result_v2/:boxId/:language?", component: OutputPage},

    { path: "/tarot_box/checkout/:boxId", component: WebCheckoutPage, props: true},
    { path: "/tarot_box/purchase_complete", component: WebCheckoutCompletePage},
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

router.beforeEach((to, from, next) => {
        to.meta.fromPath = from.fullPath;
    next();
});

// vh 단위 문제 해결
export function updateVH() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

updateVH();

const app = createApp(App);

const isProduction = process.env.NODE_ENV === 'production';

if(isProduction) {
    Sentry.init({
        app,
        dsn: "https://358dc1f1723c4f0f86d71e32a64e9cdf@o4505140750385152.ingest.sentry.io/4505463570169856",
        ignoreErrors: ["null is not an object (evaluating '_.value.contains')","Cannot read properties of null (reading 'contains')"],
        beforeSend(event,hint) { //eslint-disable-line
            const currentRoute = window.location.pathname; // 현재 URL 경로
            const ignoreRoutes = ['/tarot_box/review'];
            if (ignoreRoutes.includes(currentRoute)) {
                return null; // 이 경우 Sentry로 오류가 보고되지 않음
            }
            return event; // 그 외의 경우 오류 보고
        },
        integrations: [
            new Sentry.BrowserTracing({
                tracingOrigins: ["*"],
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            }),
            new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}


const eventBus = mitt();

//웹마이타로 분기처리 전역 디렉티브, 데이터
const WebDirective = {
    beforeMount(el) {
        const params = new URLSearchParams(window.location.search);
        const isWeb = params.get('is_web');
        const osType = params.get('os_type');
        if (isWeb !== 'true' && osType !== 'web') {
            el.style.display = 'none';
        }
    }
};
const AppDirective = {
    beforeMount(el) {
        const params = new URLSearchParams(window.location.search);
        const isWeb = params.has('is_web');
        const osType = params.get('os_type');
        if (isWeb || osType === 'web') {
            el.style.display = 'none';
        }
    }
};

//앱빌드 83번 이상 상하단 패딩 확보
export const vSafeAreaTop = {
    mounted(el) {
        if (isNewAppVersion()) {
            const urlParams = new URLSearchParams(window.location.search);
            const insetTop = parseInt(urlParams.get('inset_top') || '0', 10);

            if (insetTop > 0) {
                el.style.paddingTop = `calc(${el.style.paddingTop || '0px'} + ${insetTop}px)`;
            }
        }
    }
};

export const vSafeAreaBottom = {
    mounted(el) {
        if (isNewAppVersion()) {
            el.style.paddingBottom = `calc(${el.style.paddingBottom || '0px'} + 20px)`;
        }
    }
};

app.config.globalProperties.$isWeb = () => {
    const params = new URLSearchParams(window.location.search);
    const isWeb = params.get('is_web') === 'true';
    const osType = params.get('os_type') === 'web';
    return isWeb || osType;
};

app.config.errorHandler = (err, vm, info) => {
    if (err instanceof TypeError && err.message.includes("null is not an object (evaluating '_.value.contains')")) {
        console.warn('Ignored TypeError from vue-bottom-sheet:', err.message);
        return;
    }
    // 다른 에러 처리
    console.error('Unhandled error:', err, info);
};


app.directive('web', WebDirective);
app.directive('app', AppDirective);
app.directive('safe-area-top', vSafeAreaTop)
app.directive('safe-area-bottom', vSafeAreaBottom)

app.use(router);
app.use(store);
app.use(utils);
app.provide("eventBus", eventBus);
app.use(Vue3TouchEvents,{
    swipeTolerance: 50,
});
app.use(VWave, {
    color: '#000000',
    initialOpacity: 0.3,
    duration: 0.3,
    easing: 'ease-in',
});
app.directive('debounce', vueDebounce({ lock: true, defaultTime: '500ms' }));

app.use(LoadingPlugin);
app.use(AppListenerPlugin);
app.use(AppCommanderPlugin);

app.mount("#app");

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import AppCommanderPlugin from "@/common/app_commander_plugin";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
