<template>
  <div class="reward-area">
    <div class="reward-container">
      <span class="text2">
        받을 수 있는 리뷰 클로버
      </span>
      <span class="title3">
        +{{reward}}
      </span>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.96118 9.00001C8.9743 8.98559 8.98735 8.9711 9.00032 8.95653C9.01328 8.9711 9.02633 8.98559 9.03945 9C9.02633 9.01442 9.01328 9.02891 9.00032 9.04348C8.98735 9.02891 8.9743 9.01442 8.96118 9.00001ZM9.00032 16.2693C8.05308 17.3332 6.69312 18 5.18213 18C2.32012 18 0 15.6076 0 12.6564C0 11.242 0.532948 9.95587 1.40307 9.00001C0.532948 8.04415 0 6.75806 0 5.34361C0 2.39242 2.32012 0 5.18213 0C6.69312 0 8.05308 0.666842 9.00032 1.73069C9.94755 0.666842 11.3075 0 12.8185 0C15.6805 0 18.0006 2.39242 18.0006 5.34361C18.0006 6.75806 17.4677 8.04415 16.5976 9.00001C17.4677 9.95587 18.0006 11.242 18.0006 12.6564C18.0006 15.6076 15.6805 18 12.8185 18C11.3075 18 9.94755 17.3332 9.00032 16.2693Z" fill="#4BD1A9"/>
        <path d="M9.41817 13.1877C8.85653 13.1877 8.3309 13.0905 7.84127 12.8961C7.35165 12.6945 6.92322 12.4173 6.556 12.0645C6.19598 11.7117 5.91156 11.3012 5.70275 10.8332C5.50114 10.358 5.40033 9.84675 5.40033 9.29951C5.40033 8.74508 5.50114 8.23385 5.70275 7.76582C5.91156 7.29059 6.19958 6.87657 6.5668 6.52375C6.93402 6.17093 7.35885 5.89731 7.84127 5.7029C8.3309 5.50129 8.85653 5.40048 9.41817 5.40048C9.83579 5.40048 10.239 5.45809 10.6278 5.57329C11.0167 5.6885 11.3767 5.85051 11.7079 6.05932C12.0463 6.26813 12.3343 6.52015 12.572 6.81537L11.5135 7.86303C11.2399 7.52461 10.9231 7.26899 10.563 7.09618C10.2102 6.92337 9.82859 6.83697 9.41817 6.83697C9.07975 6.83697 8.76293 6.90177 8.46771 7.03138C8.17249 7.15379 7.91688 7.3266 7.70087 7.54981C7.48485 7.76582 7.31564 8.02504 7.19324 8.32746C7.07083 8.62267 7.00963 8.94669 7.00963 9.29951C7.00963 9.64513 7.07083 9.96915 7.19324 10.2716C7.31564 10.5668 7.48845 10.826 7.71167 11.0492C7.93488 11.2652 8.1941 11.4344 8.48931 11.5568C8.79173 11.6793 9.11935 11.7405 9.47217 11.7405C9.86819 11.7405 10.239 11.6577 10.5846 11.492C10.9303 11.3264 11.2363 11.0888 11.5027 10.7792L12.5288 11.7945C12.2911 12.0825 12.0067 12.3309 11.6755 12.5397C11.3443 12.7485 10.9843 12.9105 10.5954 13.0257C10.2138 13.1337 9.82139 13.1877 9.41817 13.1877Z" fill="#121212"/>
      </svg>
    </div>
    <div class="notice" @click="$emit('open-popup')">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 17C12.2833 17 12.5208 16.9042 12.7125 16.7125C12.9042 16.5208 13 16.2833 13 16V12C13 11.7167 12.9042 11.4792 12.7125 11.2875C12.5208 11.0958 12.2833 11 12 11C11.7167 11 11.4792 11.0958 11.2875 11.2875C11.0958 11.4792 11 11.7167 11 12V16C11 16.2833 11.0958 16.5208 11.2875 16.7125C11.4792 16.9042 11.7167 17 12 17ZM12 9C12.2833 9 12.5208 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8C11 8.28333 11.0958 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="#555555"/>
      </svg>
    </div>
  </div>
</template>

<script>

export default {
  name: "ClaimableReviewReward",
  props: {
    reward: {
      type: Number,
      required: true
    }
  },
  emits: ['open-popup'],
}
</script>

<style scoped>
.reward-area{
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  box-sizing: border-box;
}

.reward-container{
  flex: 1;
  display: flex;
  padding: 12px;
  align-items: center;
  text-align: left;
  box-sizing: border-box;

  border-radius: 8px;
  border: 1px solid var(--Grayscale-Gray10, #ECECEC);
  background: var(--Grayscale-Gray11, #F5F5F5);
}

.notice{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px;
  border-radius: 8px;
  background: var(--Color-palette-Primary-Lighten4);
  border: 1px solid var(--Color-palette-Primary-Lighten3);

}

.text2{
  color: var(--Grayscale-Gray4);
  margin-right: 4px;
}

.title3{
  margin-right: 2px;
}
</style>